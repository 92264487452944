import './styles.scss'
import React, { useState } from 'react'
import LayoutContainer from 'Components/LayoutComponent'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Field, Form } from 'react-final-form'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import { InputSwitch } from 'primereact/inputswitch'
import { InputTextarea } from 'primereact/inputtextarea'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTE_STRINGS } from 'Utils/Constants'

const AddUSDACoffeeReport = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [addUSDAReport, setAddUSDAReport] = useState({
    tags: '',
    title: '',
    content: '',
    reportLink: '',
    publishedDate: '',
    formattedDate: '',
    countryId: 102,
    isUpcoming: false
  })
  const [dialogState, setDialogState] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => postUSDACoffeeReportData(), onDismiss: () => handleDismiss(), onSuccess: () => handleSuccess() })
  const { tags, title, content, reportLink, publishedDate, countryId, isUpcoming, formattedDate } = addUSDAReport

  const isButtonDisabled = !tags || (!title && !isUpcoming) || (!reportLink && !isUpcoming) || !publishedDate || (!content && !isUpcoming)

  const postUSDACoffeeReportData = async () => {
    setLoading(true)

    try {
      const { formattedDate, ...updatedPayload } = addUSDAReport
      const response = await apiAdapterCoffeeWeb.postUSDACoffeeReport(updatedPayload)

      if (response.data.isSuccess) {
        setDialogState({
          ...dialogState,
          showModal: true,
          modalType: 'success',
          message: 'POST_USDA_REPORT_DATA_SUCCESS',
          onHide: () => handleSuccess()
        })
      }
    } catch (error) {
      setDialogState({
        ...dialogState,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
        onHide: () => handleDismiss()
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDateChange = (event) => {
    const currentDate = event.value

    const date = new Date(currentDate)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    const utcDate = new Date(Date.UTC(year, month, day))

    const isoStringAdjusted = utcDate.toISOString()

    setAddUSDAReport((previousData) => ({
      ...previousData,
      formattedDate: currentDate,
      publishedDate: isoStringAdjusted
    }))
  }

  const onSubmit = (data) => {
    setAddUSDAReport(data)
  }

  const handleCountryChange = (countryId) => {
    setAddUSDAReport((previousData) => ({
      ...previousData,
      countryId
    }))
  }

  const validate = (data) => {
    const errors = {}

    const { tags, reportLink, title, content, publishedDate } = data

    if (!tags) {
      errors.tags = t('TAG_IS_REQUIRED')
    }

    if (!title) {
      errors.title = t('TITLE_IS_REQUIRED')
    }

    if (!content) {
      errors.content = t('CONTENT_IS_REQUIRED')
    }

    if (!reportLink) {
      errors.reportLink = t('REPORT_LINK_IS_REQUIRED')
    }

    if (!publishedDate) {
      errors.publishedDate = t('DATE_IS_REQUIRED')
    }

    return errors
  }

  const toggleButton = () => {
    setAddUSDAReport({ ...addUSDAReport, isUpcoming: !isUpcoming })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target

    setAddUSDAReport({ ...addUSDAReport, [name]: value })
  }

  const handleSubmit = () => {
    postUSDACoffeeReportData()
  }

  const handleDismiss = () => {
    setDialogState({ ...dialogState, showModal: false })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listUSDACoffeeReport)
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <div>
      <LayoutContainer title={'ADD_USDA_COFFEE_REPORT'} breadCrumbsTitle={'addUSDACoffeeReport'}>
        <div className="add-usda-report-container" data-testid="add-usda-form">
          <Form
            onSubmit={onSubmit}
            initialValues={addUSDAReport}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="toggle-button-container">
                  <label htmlFor="toggleButton" className="toggle-button">
                    {t('IS_UPCOMING')}
                  </label>
                  <div className="input-switch-wrapper">
                    <InputSwitch checked={addUSDAReport?.isUpcoming} onChange={toggleButton} />
                  </div>
                </div>
                <div className="field">
                  <CountrySelector isFormComponent={true} countryId={countryId} selectCurrency={handleCountryChange} />
                </div>

                <Field
                  name="tags"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="tags" {...input} value={addUSDAReport.tags} onChange={handleInputChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="languageName" />
                        <label htmlFor="tags" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                          {t('TAG')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />

                <Field
                  name="title"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="title" {...input} value={addUSDAReport.title} disabled={isUpcoming} onChange={handleInputChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="title" />
                        <label htmlFor="title" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                          {t('TITLE')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
                <Field
                  name="content"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputTextarea disabled={isUpcoming} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} {...input} type="text" name="content" onChange={handleInputChange} value={content} rows={3} placeholder={t('ENTER_REPORT_CONTENT')} id="content" data-testid="content" />
                        <label htmlFor="content" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                          {t('CONTENT')}
                          <span className="required-field"> *</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
                <Field
                  name="reportLink"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="reportLink" {...input} value={addUSDAReport.reportLink} disabled={isUpcoming} onChange={handleInputChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="reportLink" />
                        <label htmlFor="reportLink" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                          {t('REPORT_LINK')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
                <Field
                  name="publishedDate"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <Calendar {...input} id="publishedDate" name="publishedDate" value={formattedDate} minDate={new Date()} onChange={(event) => handleDateChange(event)} showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                        <label htmlFor="publishedDate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                          {t('PUBLISH_DATE')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message"> {getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
              </form>
            )}
          />

          <div className="button-container">
            <ButtonComponent type={'submit'} disabled={isButtonDisabled} onClick={handleSubmit} />
          </div>
        </div>

        <DialogModal {...dialogState} />
      </LayoutContainer>
    </div>
  )
}

export default Loader(AddUSDACoffeeReport)
