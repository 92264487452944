import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { useHistory } from 'react-router-dom'
import LayoutContainer from 'Components/LayoutComponent'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import { Column } from 'primereact/column'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import { Button } from 'primereact/button'
import { DialogModal } from 'Components/UIComponents'
import { commonMethods } from 'Utils/commonMethods'

const ListUSDACoffeeReport = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [usdaReport, setUsdaReport] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [dialogState, setDialogState] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onDismiss: () => handleDismiss() })

  const paginationButtons = windowSize < TABLE_CONST.maxWidthTab ? TABLE_CONST.mobilePaginationButtonCount : TABLE_CONST.paginationButtonCount

  useEffect(() => {
    fetchCountryList()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const fetchCountryList = async () => {
    setShowTableMessage(true)
    setLoading(true)

    try {
      const { data: countries } = await apiAdapterCoffeeWeb.getAllCountries()

      fetchUSDACoffeeReport(countries)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const fetchUSDACoffeeReport = async (countries) => {
    setDialogState({ ...dialogState, showModal: false })
    try {
      const usdaReportData = await apiAdapterCoffeeWeb.getUSDACoffeeReport()

      if (usdaReportData?.data?.returnLst) {
        const filteredReportData = usdaReportData.data.returnLst.map((ele) => {
          const localDate = commonMethods.utcToLocalDate(ele.publishedDate)

          const countryName = countries.find((item) => item.id === ele.countryId)

          return { ...ele, countryId: countryName.nicename, publishedDate: localDate, countryIdForEdit: countryName.id, publishedDateInUTC: ele.publishedDate }
        })

        setUsdaReport(filteredReportData)
      } else {
        setDialogState({
          ...dialogState,
          showModal: true,
          modalType: 'error',
          message: 'USDA_COFFEE_REPORT_NO_DATA',
          onHide: () => handleDismiss(),
          onRetry: () => fetchUSDACoffeeReport(countries)
        })
      }
    } catch (error) {
      setDialogState({
        ...dialogState,
        showModal: true,
        modalType: 'error',
        message: 'USDA_COFFEE_REPORT_DATA_FAILED',
        onHide: () => handleDismiss(),
        onRetry: () => fetchUSDACoffeeReport(countries)
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const handleAddButtonClick = () => {
    history.push(ROUTE_STRINGS.addUSDACoffeeReport)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setDialogState({ ...dialogState, showModal: false })
  }

  const handleDeleteConfirmation = (rowData) => {
    setDialogState({
      ...dialogState,
      showModal: true,
      modalType: 'info',
      message: t('DELETE_USDA_COFFEE_REPORT'),
      onConfirmation: () => deleteUSDAReport(rowData),
      onHide: () => handleDismiss()
    })
  }

  const deleteUSDAReport = async ({ idReport }) => {
    setLoading(true)
    try {
      await apiAdapterCoffeeWeb.deleteUSDACoffeeReport(idReport)
      setDialogState({
        ...dialogState,
        showModal: false
      })
      const updatedReport = usdaReport.filter((el) => el.idReport !== idReport)

      setUsdaReport(updatedReport)
    } catch (error) {
      setDialogState({
        ...dialogState,
        showModal: true,
        modalType: 'error',
        message: 'DELETE_USDA_COFFEE_REPORT_FAILED',
        onHide: () => handleDismiss(),
        onRetry: () => deleteUSDAReport(idReport)
      })
    } finally {
      setLoading(false)
    }
  }

  const handleReportClick = (rowData) => {
    console.log(rowData)
    if (rowData?.isUpcoming) {
      history.push({ pathname: `${ROUTE_STRINGS.editUSDACoffeeReport}/${rowData.idReport}`, state: rowData })
    }
  }

  const renderTableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  const renderActionColumn = (rowData) => (
    <div className="action-container">
      <Button type="button" icon="pi pi-trash" className="pi-delete-icon" tooltip={t('DELETE')} onClick={() => handleDeleteConfirmation(rowData)} tooltipOptions={{ position: 'top' }} data-testid="delete-button" />
    </div>
  )

  const renderUpcomingColumn = (rowData) => (
    <div className={`${rowData?.isUpcoming ? 'upcoming' : 'published'}`} onClick={() => handleReportClick(rowData)}>
      {rowData?.isUpcoming ? 'Upcoming' : 'Published'}
    </div>
  )

  const columns = [
    { body: renderUpcomingColumn, header: t('IS_UPCOMING'), className: 'upcoming-column' },
    { field: 'countryId', header: t('COUNTRY'), className: 'countryName' },
    { field: 'tags', header: t('TAG'), className: 'tag-column' },
    { field: 'title', header: t('TITLE'), className: 'title-column' },
    // { field: 'content', header: t('CONTENT'), className: 'content-column' },
    { field: 'reportLink', header: t('REPORT_LINK'), className: 'report-link-column' },
    { field: 'publishedDate', header: t('PUBLISH_DATE'), className: 'publish-date-column' },
    { body: renderActionColumn, header: t('ACTIONS'), className: 'usda-actions-column' }
  ]

  return (
    <div className="usda-report-table">
      <LayoutContainer title={'USDA_COFFEE_REPORT'} breadCrumbsTitle={'usdaCoffeeReport'} showAddButton={true} handleAddButtonClick={handleAddButtonClick}>
        <DataTable value={usdaReport} paginator={usdaReport?.length > 0} rows={5} pageLinkSize={paginationButtons} emptyMessage={renderTableResponseMessage} className="usda-columns-container">
          {columns.map(({ field, header, body, className }) => (
            <Column key={field} field={field} header={header} body={body} className={className} />
          ))}
        </DataTable>

        <DialogModal {...dialogState} />
      </LayoutContainer>
    </div>
  )
}

export default Loader(ListUSDACoffeeReport)
