import './styles.scss'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { ButtonComponent } from 'Components/UIComponents'

const AddPortWiseStockReport = ({ showDialog, setShowDialog, selectedMarket, marketName, rowDetails }) => {
  const { t } = useTranslation()

  const [selectedDate, setSelectedDate] = useState('')
  const [dropdownValue, setDropdownValue] = useState({})
  const [formData, setFormData] = useState({
    marketType: '',
    date: '',
    valid: '',
    comparedToPreDay: '',
    nonTenderable: '',
    suspended: '',
    port: ''
  })

  const [inputForm, setInputForm] = useState({
    marketId: '',
    country: '',
    Date: '',
    Antwerp: '',
    HamburgBremen: '',
    Houston: '',
    NewOrleans: '',
    NewYork: '',
    Virginia: '',
    Total: '',
    barcelona: '',
    miami: '',
    ComparedPrevDay: ''
  })

  const [validateField, setValidateField] = useState({
    marketType: false,
    country: false,
    date: false,
    valid: false,
    comparedToPreDay: false,
    nonTenderable: false,
    suspended: false,
    marketId: false,
    Date: false,
    Antwerp: false,
    HamburgBremen: false,
    Houston: false,
    NewOrleans: false,
    NewYork: false,
    Virginia: false,
    Total: false,
    barcelona: false,
    miami: false,
    ComparedPrevDay: false
  })

  // TO DO :
  // useEffect(() => {
  //   if (rowDetails) {
  //     setFormData({
  //       marketType: selectedMarket.marketName,
  //       date: rowDetails.date,
  //       valid: rowDetails.valid,
  //       comparedToPreDay: rowDetails.comparedToPreDay,
  //       nonTenderable: rowDetails.nonTenderable,
  //       suspended: rowDetails.suspended,
  //       port: ''
  //     })
  //     setDropdownValue(selectedMarket)
  //   }
  // }, [showDialog])

  useEffect(() => {
    if (showDialog) {
      setValidateField((prev) => ({
        ...prev,
        marketType: false,
        marketId: false
      }))
    }
  }, [showDialog])

  const handleInputChange = (fieldName, fieldValue) => {
    if (selectedMarket.idMarket === 1) {
      setFormData((previousData) => ({
        ...previousData,
        [fieldName]: fieldValue
      }))
    } else {
      setInputForm((previousData) => ({
        ...previousData,
        [fieldName]: fieldValue
      }))
    }
  }

  const handleDateChange = (event) => {
    const selectedDate = event.value

    const formattedDate = selectedDate ? moment(selectedDate).format('DD-MMM-YYYY') : ''

    if (selectedMarket.idMarket === 1) {
      setSelectedDate(selectedDate)
      setFormData({ ...formData, date: formattedDate })
    } else {
      setSelectedDate(selectedDate)
      setInputForm({ ...inputForm, Date: formattedDate })
    }
  }

  const handleInputBlur = (field) => {
    setValidateField((previousState) => ({
      ...previousState,
      [field]: true
    }))
  }

  const closeDialog = () => {
    setShowDialog()
    resetFromData()
  }

  const resetFromData = () => {
    if (selectedMarket.idMarket === 1) {
      setFormData({
        marketType: '',
        date: '',
        valid: '',
        comparedToPreDay: '',
        nonTenderable: '',
        suspended: '',
        port: ''
      })
    } else {
      setInputForm({
        marketId: '',
        country: '',
        Date: '',
        Antwerp: '',
        HamburgBremen: '',
        Houston: '',
        NewOrleans: '',
        NewYork: '',
        Virginia: '',
        Total: '',
        barcelona: '',
        miami: '',
        ComparedPrevDay: ''
      })
    }

    setValidateField({
      marketType: false,
      country: false,
      date: false,
      valid: false,
      comparedToPreDay: false,
      nonTenderable: false,
      suspended: false,
      marketId: false,
      Date: false,
      Antwerp: false,
      HamburgBremen: false,
      Houston: false,
      NewOrleans: false,
      NewYork: false,
      Virginia: false,
      barcelona: false,
      miami: false,
      ComparedPrevDay: false
    })
    setSelectedDate('')
    setDropdownValue('')
  }

  const { marketType, port, date, valid, comparedToPreDay, nonTenderable, suspended } = formData
  const { marketId, Date, country, Antwerp, HamburgBremen, Houston, NewOrleans, NewYork, Virginia, barcelona, miami, ComparedPrevDay } = inputForm

  const isButtonDisabled = !marketType || !port || !date || !valid || !comparedToPreDay || !nonTenderable || !suspended
  const disableButton = !marketId || !Date || !country || !Antwerp || !HamburgBremen || !Houston || !NewOrleans || !NewYork || !Virginia || !barcelona || !miami || !ComparedPrevDay

  const footerContent = () => (
    <div className="footer-button">
      <ButtonComponent type="cancel" onClick={closeDialog} />
      <ButtonComponent type="submit" disabled={selectedMarket.idMarket === 1 ? isButtonDisabled : disableButton} />
    </div>
  )

  const dropdownValueChange = (event) => {
    const value = event.value

    setDropdownValue(value)
    if (selectedMarket.idMarket === 1) {
      setFormData({ ...formData, marketType: value.marketName })
    } else {
      setInputForm({ ...inputForm, marketId: value.marketName })
    }
  }

  return (
    <div className="add-port-wise-data-container">
      <Dialog visible={showDialog} header={`${t('PORT_WISE_STOCK_REPORT')} - ${selectedMarket.marketName}`} footer={footerContent} onHide={closeDialog} className="port-wise-dialog-modal">
        {selectedMarket.idMarket === 1 && (
          <div className="report-data-form">
            <div className="form-fields">
              <span className="p-float-label">
                <Dropdown
                  id="marketId"
                  name="marketType"
                  value={dropdownValue}
                  className={`dropdown-field ${validateField.marketType && !formData.marketType ? 'error-field' : ''}`}
                  options={marketName}
                  optionLabel="marketName"
                  onChange={dropdownValueChange}
                  onBlur={() => {
                    handleInputBlur('marketType')
                  }}
                />
                <label htmlFor="marketId" className={`field-label ${validateField.marketType && !formData.marketType ? 'error-label' : ''}`}>
                  {t('SELECT_MARKET')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.marketType && !formData.marketType && <small className="error-message">{t('MARKET_FIELD_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <Dropdown
                  id="port"
                  name="port"
                  className={`dropdown-field ${validateField.port && !formData.port ? 'error-field' : ''}`}
                  onBlur={() => {
                    handleInputBlur('port')
                  }}
                />
                <label htmlFor="port" className={`field-label ${validateField.port && !formData.port ? 'error-label' : ''}`}>
                  {t('SELECT_PORT')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.port && !formData.port && <small className="error-message">{t('PORT_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <Calendar
                  id="date"
                  name="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  onBlur={() => {
                    handleInputBlur('date')
                  }}
                  showIcon
                  className={`calendar-field ${validateField.date && !formData.date ? 'error-field' : ''}`}
                />
                <label htmlFor="date" className={`field-label ${validateField.date && !formData.date ? 'error-label' : ''}`}>
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.date && !formData.date && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="valid"
                  name="valid"
                  value={formData.valid}
                  onChange={(event) => handleInputChange('valid', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('valid')
                  }}
                  className={`text-field ${validateField.valid && !formData.valid ? 'error-field' : ''}`}
                />
                <label htmlFor="valid" className={`field-label ${validateField.valid && !formData.valid ? 'error-label' : ''}`}>
                  {t('VALID')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.valid && !formData.valid && <small className="error-message">{t('VALID_IS_REQUIRED')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="comparedToPreDay"
                  name="comparedToPreDay"
                  value={formData.comparedToPreDay}
                  onChange={(event) => handleInputChange('comparedToPreDay', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('comparedToPreDay')
                  }}
                  className={`text-field ${validateField.comparedToPreDay && !formData.comparedToPreDay ? 'error-field' : ''}`}
                />
                <label htmlFor="comparedToPreDay" className={`field-label ${validateField.comparedToPreDay && !formData.comparedToPreDay ? 'error-label' : ''}`}>
                  {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.comparedToPreDay && !formData.comparedToPreDay && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="nonTenderable"
                  name="nonTenderable"
                  value={formData.nonTenderable}
                  onChange={(event) => handleInputChange('nonTenderable', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('nonTenderable')
                  }}
                  className={`text-field ${validateField.nonTenderable && !formData.nonTenderable ? 'error-field' : ''}`}
                />
                <label htmlFor="nonTenderable" className={`field-label ${validateField.nonTenderable && !formData.nonTenderable ? 'error-label' : ''}`}>
                  {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.nonTenderable && !formData.nonTenderable && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="suspended"
                  name="suspended"
                  value={formData.suspended}
                  onChange={(event) => handleInputChange('suspended', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('suspended')
                  }}
                  className={`text-field ${validateField.suspended && !formData.suspended ? 'error-field' : ''}`}
                />
                <label htmlFor="suspended" className={`field-label ${validateField.suspended && !formData.suspended ? 'error-label' : ''}`}>
                  {t('SUSPENDED')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.suspended && !formData.suspended && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
            </div>
          </div>
        )}

        {selectedMarket.idMarket === 2 && (
          <div className="report-data-form">
            <div className="form-fields">
              <span className="p-float-label">
                <Dropdown
                  id="marketId"
                  name="marketId"
                  value={dropdownValue}
                  className={`dropdown-field ${validateField.marketId && !inputForm.marketId ? 'error-field' : ''}`}
                  options={marketName}
                  optionLabel="marketName"
                  onChange={dropdownValueChange}
                  onBlur={() => {
                    handleInputBlur('marketId')
                  }}
                />
                <label htmlFor="marketId" className={`field-label ${validateField.marketId && !inputForm.marketId ? 'error-label' : ''}`}>
                  {t('SELECT_MARKET')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.marketId && !inputForm.marketId && <small className="error-message">{t('MARKET_FIELD_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <Dropdown
                  id="country"
                  name="country"
                  className={`dropdown-field ${validateField.country && !inputForm.country ? 'error-field' : ''}`}
                  onBlur={() => {
                    handleInputBlur('country')
                  }}
                />
                <label htmlFor="country" className={`field-label ${validateField.country && !inputForm.country ? 'error-label' : ''}`}>
                  {t('SELECT_COUNTRY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.country && !inputForm.country && <small className="error-message">{t('COUNTRY_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <Calendar
                  id="Date"
                  name="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  onBlur={() => {
                    handleInputBlur('Date')
                  }}
                  showIcon
                  className={`calendar-field ${validateField.Date && !inputForm.Date ? 'error-field' : ''}`}
                />
                <label htmlFor="Date" className={`field-label ${validateField.Date && !inputForm.Date ? 'error-label' : ''}`}>
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.Date && !inputForm.Date && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="antwerp"
                  name="Antwerp"
                  value={inputForm.Antwerp}
                  onChange={(event) => handleInputChange('Antwerp', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('Antwerp')
                  }}
                  className={`text-field ${validateField.Antwerp && !inputForm.Antwerp ? 'error-field' : ''}`}
                />
                <label htmlFor="antwerp" className={`field-label ${validateField.Antwerp && !inputForm.Antwerp ? 'error-label' : ''}`}>
                  {t('ANTWERP')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.Antwerp && !inputForm.Antwerp && <small className="error-message">{t('ANTWERP_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="barcelona"
                  name="barcelona"
                  value={inputForm.barcelona}
                  onChange={(event) => handleInputChange('barcelona', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('barcelona')
                  }}
                  className={`text-field ${validateField.barcelona && !inputForm.barcelona ? 'error-field' : ''}`}
                />
                <label htmlFor="barcelona" className={`field-label ${validateField.barcelona && !inputForm.barcelona ? 'error-label' : ''}`}>
                  {t('BARCELONA')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.barcelona && !inputForm.barcelona && <small className="error-message">{t('BARCELONA_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="HamburgBremen"
                  name="HamburgBremen"
                  value={inputForm.HamburgBremen}
                  onChange={(event) => handleInputChange('HamburgBremen', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('HamburgBremen')
                  }}
                  className={`text-field ${validateField.HamburgBremen && !inputForm.HamburgBremen ? 'error-field' : ''}`}
                />
                <label htmlFor="HamburgBremen" className={`field-label ${validateField.HamburgBremen && !inputForm.HamburgBremen ? 'error-label' : ''}`}>
                  {t('HAMBURG')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.HamburgBremen && !inputForm.HamburgBremen && <small className="error-message">{t('HAMBURG_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="houston"
                  name="Houston"
                  value={inputForm.Houston}
                  onChange={(event) => handleInputChange('Houston', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('Houston')
                  }}
                  className={`text-field ${validateField.Houston && !inputForm.Houston ? 'error-field' : ''}`}
                />
                <label htmlFor="houston" className={`field-label ${validateField.Houston && !inputForm.Houston ? 'error-label' : ''}`}>
                  {t('HOUSTON')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.Houston && !inputForm.Houston && <small className="error-message">{t('HOUSTON_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="miami"
                  name="miami"
                  value={inputForm.miami}
                  onChange={(event) => handleInputChange('miami', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('miami')
                  }}
                  className={`text-field ${validateField.miami && !inputForm.miami ? 'error-field' : ''}`}
                />
                <label htmlFor="miami" className={`field-label ${validateField.miami && !inputForm.miami ? 'error-label' : ''}`}>
                  {t('MIAMI')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.miami && !inputForm.miami && <small className="error-message">{t('MIAMI_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="newOrleans"
                  name="NewOrleans"
                  value={inputForm.NewOrleans}
                  onChange={(event) => handleInputChange('NewOrleans', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('NewOrleans')
                  }}
                  className={`text-field ${validateField.NewOrleans && !inputForm.NewOrleans ? 'error-field' : ''}`}
                />
                <label htmlFor="newOrleans" className={`field-label ${validateField.NewOrleans && !inputForm.NewOrleans ? 'error-label' : ''}`}>
                  {t('NEW_ORLEANS')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.NewOrleans && !inputForm.NewOrleans && <small className="error-message">{t('NEW_ORLEANS_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="NewYork"
                  name="NewYork"
                  value={inputForm.NewYork}
                  onChange={(event) => handleInputChange('NewYork', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('NewYork')
                  }}
                  className={`text-field ${validateField.NewYork && !inputForm.NewYork ? 'error-field' : ''}`}
                />
                <label htmlFor="NewYork" className={`field-label ${validateField.NewYork && !inputForm.NewYork ? 'error-label' : ''}`}>
                  {t('NEW_YORK')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.NewYork && !inputForm.NewYork && <small className="error-message">{t('NEW_YORK_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="Virginia"
                  name="Virginia"
                  value={inputForm.Virginia}
                  onChange={(event) => handleInputChange('Virginia', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('Virginia')
                  }}
                  className={`text-field ${validateField.Virginia && !inputForm.Virginia ? 'error-field' : ''}`}
                />
                <label htmlFor="Virginia" className={`field-label ${validateField.Virginia && !inputForm.Virginia ? 'error-label' : ''}`}>
                  {t('VIRGINIA')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.Virginia && !inputForm.Virginia && <small className="error-message">{t('VIRGINIA_ERROR_MESSAGE')}</small>}
            </div>

            <div className="form-fields">
              <span className="p-float-label">
                <InputText
                  id="ComparedPrevDay"
                  name="ComparedPrevDay"
                  value={inputForm.ComparedPrevDay}
                  onChange={(event) => handleInputChange('ComparedPrevDay', event.target.value)}
                  onBlur={() => {
                    handleInputBlur('ComparedPrevDay')
                  }}
                  className={`text-field ${validateField.ComparedPrevDay && !inputForm.ComparedPrevDay ? 'error-field' : ''}`}
                />
                <label htmlFor="ComparedPrevDay" className={`field-label ${validateField.ComparedPrevDay && !inputForm.ComparedPrevDay ? 'error-label' : ''}`}>
                  {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {validateField.ComparedPrevDay && !inputForm.ComparedPrevDay && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
            </div>
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default AddPortWiseStockReport
