import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import { Toast } from 'primereact/toast'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'

const AddSubscriptionPaymentMapping = ({ setLoading, paymentTypeErrorMock, subscriptionTypeErrorMock, dropdownDisabledMock, subscriptionMapMock, loadingMock }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const toast = useRef(null)
  const location = useLocation()
  const params = new URLSearchParams()
  const subscriptionId = location?.state?.id

  const [subscriptionTypes, setSubscriptionTypes] = useState([])
  const [paymentTypes, setSubPaymentIdTypes] = useState([])
  const [subscriptionTypeError, setSubscriptionTypeError] = useState(false)
  const [paymentTypeError, setSubPaymentIdTypeError] = useState(false)
  const [dropdownDisabled, setDropdownDisabled] = useState(false)
  const [subscriptionMap, setSubscriptionMap] = useState({
    idSubscription: '',
    paymentId: ''
  })
  const [modalState, setModalState] = useState({
    showModal: false,
    modalType: '',
    message: '',
    onRetry: () => {}
  })

  const { idSubscription, paymentId } = subscriptionMap
  const isButtonDisabled = !idSubscription || !paymentId

  useEffect(() => {
    init()
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (dropdownDisabledMock) {
      setSubPaymentIdTypeError(paymentTypeErrorMock)
      setSubscriptionTypeError(subscriptionTypeErrorMock)
      setDropdownDisabled(dropdownDisabledMock)
      setSubscriptionMap(subscriptionMapMock)
      setLoading(loadingMock)
    }
  }, [])

  const init = async (onRetry) => {
    setLoading(true)
    const fetchAllSubscriptions = apiAdapterCoffeeWeb.getSubscriptionMaster()
    const fetchAllPayments = apiAdapterCoffeeWeb.getAllPaymentType()

    try {
      const [fetchAllSubscriptionsResponse, fetchAllPaymentsResponse] = await Promise.all([fetchAllSubscriptions, fetchAllPayments])

      setSubscriptionTypes(fetchAllSubscriptionsResponse.data.returnLst)
      setSubPaymentIdTypes(fetchAllPaymentsResponse.data.returnLst)

      const sleetedSubscription = fetchAllSubscriptionsResponse.data.returnLst.find(({ id }) => id === subscriptionId)

      setDropdownDisabled(sleetedSubscription)
      setSubscriptionMap({ ...subscriptionMap, idSubscription: sleetedSubscription.id })
    } catch {
      if (!onRetry) {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'error',
          message: 'NO_DATA_FOUND',
          onRetry: () => init('onRetry')
        })
      } else {
        handleDismiss()
        setSubPaymentIdTypeError(true)
        setSubscriptionTypeError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const addSubscriptionMap = async () => {
    setLoading(true)
    handleDismiss()
    const data = { idSubscription, paymentId }

    try {
      const addSubscriptionMapResponse = await apiAdapterCoffeeWeb.postSubscriptionPaymentMapping(data)

      if (addSubscriptionMapResponse.data.statusCode === 200) {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'success',
          message: 'ADDED_PAYMENT_MAPPING_SUCCESSFULLY'
        })
      }
      if (addSubscriptionMapResponse.data.statusCode === 404) {
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_SELECT_ANOTHER_PAYMENT_NAME') })
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'error',
        message: 'SUBSCRIPTION_PAYMENT_TYPE_WERE_NOT_ADDED',
        onRetry: addSubscriptionMap
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    if (['idSubscription', 'paymentId'].includes(name)) {
      const intValue = parseInt(value, 10)

      setSubscriptionMap((prevData) => ({
        ...prevData,
        [name]: intValue
      }))
    }
  }

  const validate = (data) => {
    const errors = {}

    const { idSubscription, paymentId } = data

    if (!idSubscription) {
      errors.idSubscription = t('SUBSCRIPTION_NAME_IS_REQUIRED')
    }

    if (!paymentId) {
      errors.paymentId = t('PAYMENT_NAME_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const handleSaveButton = (e) => {
    e.preventDefault()
    addSubscriptionMap()
  }

  const onSubmit = (data) => {
    setSubscriptionMap(data)
  }

  const handleSuccess = () => {
    params.set('tab', 'subscription_payment')
    history.push({ pathname: `${ROUTE_STRINGS.subscriptionConfig}`, search: params.toString(), state: location.state })
  }

  const handleDismiss = () => {
    if (modalState.modalType === 'success') {
      handleSuccess()
    } else setModalState((previousState) => ({ ...previousState, showModal: false }))
  }

  return (
    <LayoutContainer title={'SUBSCRIPTION_PAYMENT_TYPE'} breadCrumbsTitle={'addSubscriptionPaymentType'}>
      <div className="subscription-payment-type" data-testid="addSubscriptionPayment-form">
        <Form
          onSubmit={onSubmit}
          initialValues={subscriptionMap}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="idSubscription"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Dropdown
                        {...input}
                        id="idSubscription"
                        name="idSubscription"
                        value={subscriptionMap.idSubscription}
                        options={subscriptionTypes
                          .filter((item) => item.isActive && item.basePrice > 0)
                          .map((item) => ({
                            label: item.name,
                            value: item.id
                          }))}
                        onChange={handleChange}
                        placeholder={t('SELECT_SUBSCRIPTION')}
                        className={classNames('dropdown-field', { 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                        disabled={subscriptionTypeError || dropdownDisabled}
                        data-testid="subscriptionNameDropdown"
                      />
                      <label htmlFor="idSubscription" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('SUBSCRIPTION_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                    {subscriptionTypeError && <span className="error-status">{t('ERROR_WHILE_FETCHING_SUBSCRIPTION_NAME')}</span>}
                  </div>
                )}
              />

              <Field
                name="paymentId"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Dropdown
                        {...input}
                        id="paymentId"
                        name="paymentId"
                        value={subscriptionMap.paymentId}
                        options={paymentTypes
                          .filter((item) => item.isActive)
                          .map((item) => ({
                            label: item.paymentName,
                            value: item.idPayment
                          }))}
                        onChange={handleChange}
                        placeholder={t('SELECT_PAYMENT')}
                        className={classNames('dropdown-field', { 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                        disabled={paymentTypeError}
                        data-testid="paymentNameDropdown"
                      />
                      <label htmlFor="paymentId" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('PAYMENT_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                    {paymentTypeError && <span className="error-status">{t('ERROR_WHILE_FETCHING_PAYMENT_NAME')}</span>}
                  </div>
                )}
              />
            </form>
          )}
        />

        <div className="add-subscription-type-container-button">
          <ButtonComponent type={'submit'} disabled={isButtonDisabled} onClick={handleSaveButton} />
        </div>
      </div>
      <DialogModal {...modalState} onDismiss={handleDismiss} onSuccess={handleSuccess} onHide={handleDismiss} />
      <Toast ref={toast} position="top-right" />
    </LayoutContainer>
  )
}

export default Loader(AddSubscriptionPaymentMapping)
