import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { ButtonComponent } from 'Components/UIComponents'

const AddCountryStockReport = ({ showDialog, setShowDialog, selectedItem, marketName }) => {
  const { t } = useTranslation()

  const [robustaDateSelection, setRobustaDateSelection] = useState('')
  const [arabicaDateSelection, setArabicaDateSelection] = useState('')
  const [selectedMarket, setSelectedMarket] = useState({})
  const [robustaFormData, setRobustaFormData] = useState({
    marketType: '',
    date: '',
    origin: '',
    valid: '',
    compareToPreDay: '',
    NonTenderable: '',
    suspend: ''
  })

  const [arabicaFormData, setArabicaFormData] = useState({
    marketType: '',
    date: '',
    origin: '',
    antwerp: '',
    barcelona: '',
    hamburg: '',
    houston: '',
    miami: '',
    newOrleans: '',
    newYork: '',
    virginia: '',
    compareToPreDay: ''
  })

  const [touchedFields, setTouchedFields] = useState({
    marketType: false,
    origin: false,
    date: false,
    valid: false,
    compareToPreDay: false,
    NonTenderable: false,
    suspend: false,
    antwerp: false,
    barcelona: false,
    hamburg: false,
    houston: false,
    miami: false,
    newOrleans: false,
    newYork: false,
    virginia: false
  })

  const { date: robustaDate, origin: robustaOrigin, valid: robustaValid, compareToPreDay: robustaCompareToPreDay, NonTenderable, suspend, marketType } = robustaFormData
  const { date: arabicaDate, origin: arabicaOrigin, compareToPreDay: arabicaCompareToPreDay, antwerp, barcelona, hamburg, houston, miami, newOrleans, newYork, virginia, marketType: arabicaMarketType } = arabicaFormData

  useEffect(() => {
    if (showDialog) {
      setTouchedFields((prev) => ({
        ...prev,
        marketType: false
      }))
    }
  }, [showDialog])

  const handleChange = (fieldName, value) => {
    if (selectedItem.idMarket === 1) {
      setRobustaFormData((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    } else {
      setArabicaFormData((previousData) => ({
        ...previousData,
        [fieldName]: value
      }))
    }
  }

  const handleBlur = (fieldName) => {
    setTouchedFields((prev) => ({
      ...prev,
      [fieldName]: true
    }))
  }

  const handleDateChange = (event) => {
    const selectedDate = event.value

    const formattedDate = selectedDate ? moment(selectedDate).format('DD-MMM-YYYY') : ''

    if (selectedItem.idMarket === 1) {
      setRobustaDateSelection(selectedDate)
      setRobustaFormData({ ...robustaFormData, date: formattedDate })
    } else {
      setArabicaDateSelection(selectedDate)
      setArabicaFormData({ ...arabicaFormData, date: formattedDate })
    }
  }

  const closeDialog = () => {
    setShowDialog(false)
    resetData()
  }

  const handleDropDown = (event) => {
    const value = event.value

    setSelectedMarket(value)

    if (selectedItem.idMarket === 1) {
      setRobustaFormData({ ...robustaFormData, marketType: value.marketName })
    } else {
      setArabicaFormData({ ...arabicaFormData, marketType: value.marketName })
    }
  }

  const resetData = () => {
    if (selectedItem.idMarket === 1) {
      setRobustaFormData({
        date: '',
        origin: '',
        valid: '',
        compareToPreDay: '',
        NonTenderable: '',
        suspend: '',
        marketType: ''
      })
      setRobustaDateSelection('')
    } else {
      setArabicaFormData({
        date: '',
        origin: '',
        antwerp: '',
        barcelona: '',
        hamburg: '',
        houston: '',
        miami: '',
        newOrleans: '',
        newYork: '',
        virginia: '',
        compareToPreDay: '',
        marketType: ''
      })
      setArabicaDateSelection('')
    }

    setSelectedMarket('')
    setTouchedFields({
      origin: false,
      date: false,
      valid: false,
      compareToPreDay: false,
      NonTenderable: false,
      suspend: false,
      antwerp: false,
      barcelona: false,
      hamburg: false,
      houston: false,
      miami: false,
      newOrleans: false,
      newYork: false,
      virginia: false,
      marketType: false
    })
  }

  const isButtonDisabled = !robustaDate || !robustaOrigin || !robustaValid || !robustaCompareToPreDay || !NonTenderable || !suspend || !marketType
  const submitButtonDisabled = !arabicaDate || !arabicaOrigin || !arabicaCompareToPreDay || !antwerp || !barcelona || !hamburg || !houston || !miami || !newOrleans || !newYork || !virginia || !arabicaMarketType

  const footerContent = () => (
    <div className="button-container">
      <ButtonComponent type="cancel" onClick={closeDialog} />
      <ButtonComponent type="submit" disabled={selectedItem.idMarket === 1 ? isButtonDisabled : submitButtonDisabled} />
    </div>
  )

  return (
    <div className="country-wise-dialog-container">
      <Dialog visible={showDialog} header={`${t('ADD_COUNTRY_STOCK_REPORT')} - ${selectedItem.marketName}`} onHide={closeDialog} className="country-wise-dialog-modal" footer={footerContent}>
        {selectedItem.idMarket === 1 && (
          <div className="form-container">
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Dropdown id="marketType" placeholder={t('SELECT_MARKET')} name="marketType" value={selectedMarket} onChange={handleDropDown} options={marketName} optionLabel="marketName" className={`input-field ${touchedFields.marketType && !robustaFormData.marketType ? 'error' : ''}`} onBlur={() => handleBlur('marketType')} />
                <label htmlFor="marketType" className={`field-label ${touchedFields.marketType && !robustaFormData.marketType ? 'error' : ''}`}>
                  {t('SELECT_MARKET')} <span className="required-text-field">*</span>{' '}
                </label>
              </span>
              {touchedFields.marketType && !robustaFormData.marketType && <small className="error-message">{t('MARKET_FIELD_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Dropdown placeholder={t('CHOOSE_ORIGIN')} name="origin" className={`input-field ${touchedFields.origin && !robustaFormData.origin ? 'error' : ''}`} onBlur={() => handleBlur('origin')} />
                <label htmlFor="idMarket" className={`field-label ${touchedFields.origin && !robustaFormData.origin ? 'error' : ''}`}>
                  {t('CHOOSE_ORIGIN')} <span className="required-text-field">*</span>{' '}
                </label>
              </span>
              {touchedFields.origin && !robustaFormData.origin && <small className="error-message">{t('PORT_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Calendar
                  id="date"
                  name="date"
                  value={robustaDateSelection}
                  onChange={handleDateChange}
                  onBlur={() => {
                    handleBlur('date')
                  }}
                  maxDate={new Date()}
                  showIcon
                  className={`calendar-field ${touchedFields.date && !robustaFormData.date ? 'error' : ''}`}
                />
                <label htmlFor="date" className={`field-label ${touchedFields.date && !robustaFormData.date ? 'error' : ''}`}>
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.date && !robustaFormData.date && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="valid"
                  name="valid"
                  value={robustaFormData.valid}
                  className={`input-field ${touchedFields.valid && !robustaFormData.valid ? 'error' : ''}`}
                  onChange={(event) => {
                    handleChange('valid', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('valid')
                  }}
                />
                <label htmlFor="valid" className={`field-label ${touchedFields.valid && !robustaFormData.valid ? 'error' : ''}`}>
                  {t('VALID')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.valid && !robustaFormData.valid && <small className="error-message">{t('This field is required')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="compareToPreDay"
                  name="compareToPreDay"
                  value={robustaFormData.compareToPreDay}
                  onChange={(event) => {
                    handleChange('compareToPreDay', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('compareToPreDay')
                  }}
                  className={`input-field ${touchedFields.compareToPreDay && !robustaFormData.compareToPreDay ? 'error' : ''}`}
                />
                <label htmlFor="compareToPreDay" className={`field-label ${touchedFields.compareToPreDay && !robustaFormData.compareToPreDay ? 'error' : ''}`}>
                  {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.compareToPreDay && !robustaFormData.compareToPreDay && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="nonTenderable"
                  name="NonTenderable"
                  value={robustaFormData.NonTenderable}
                  onChange={(event) => {
                    handleChange('NonTenderable', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('NonTenderable')
                  }}
                  className={`input-field ${touchedFields.NonTenderable && !robustaFormData.NonTenderable ? 'error' : ''}`}
                />
                <label htmlFor="nonTenderable" className={`field-label ${touchedFields.NonTenderable && !robustaFormData.NonTenderable ? 'error' : ''}`}>
                  {t('NON_TENDERABLE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.NonTenderable && !robustaFormData.NonTenderable && <small className="error-message">{t('NON_TENDERABLE_ERROR_MESSAGE')}</small>}
            </div>
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="suspend"
                  name="suspend"
                  value={robustaFormData.suspend}
                  onChange={(event) => {
                    handleChange('suspend', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('suspend')
                  }}
                  className={`input-field ${touchedFields.suspend && !robustaFormData.suspend ? 'error' : ''}`}
                />
                <label htmlFor="suspend" className={`field-label ${touchedFields.suspend && !robustaFormData.suspend ? 'error' : ''}`}>
                  {t('SUSPENDED')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.suspend && !robustaFormData.suspend && <small className="error-message">{t('SUSPENDED_ERROR_MESSAGE')}</small>}
            </div>
          </div>
        )}

        {selectedItem.idMarket === 2 && (
          <div className="form-container">
            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Dropdown id="marketType" placeholder={t('SELECT_MARKET')} name="marketType" value={selectedMarket} onChange={handleDropDown} options={marketName} optionLabel="marketName" className={`input-field ${touchedFields.marketType && !arabicaFormData.marketType ? 'error' : ''}`} onBlur={() => handleBlur('marketType')} />
                <label htmlFor="marketType" className={`field-label ${touchedFields.marketType && !arabicaFormData.marketType ? 'error' : ''}`}>
                  {t('SELECT_MARKET')} <span className="required-text-field">*</span>{' '}
                </label>
              </span>
              {touchedFields.marketType && !arabicaFormData.marketType && <small className="error-message">{t('MARKET_FIELD_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Dropdown placeholder="Select Origin" name="origin" className={`input-field ${touchedFields.origin && !arabicaFormData.origin ? 'error' : ''}`} onBlur={() => handleBlur('origin')} />
                <label htmlFor="idMarket" className={`field-label ${touchedFields.origin && !arabicaFormData.origin ? 'error' : ''}`}>
                  {t('Select Origin')} <span className="required-text-field">*</span>{' '}
                </label>
              </span>
              {touchedFields.origin && !arabicaFormData.origin && <small className="error-message">{t('ORIGIN_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <Calendar
                  id="date"
                  name="date"
                  value={arabicaDateSelection}
                  onChange={handleDateChange}
                  onBlur={() => {
                    handleBlur('date')
                  }}
                  maxDate={new Date()}
                  showIcon
                  className={`calendar-field ${touchedFields.date && !arabicaFormData.date ? 'error' : ''}`}
                />
                <label htmlFor="date" className={`field-label ${touchedFields.date && !arabicaFormData.date ? 'error' : ''}`}>
                  {t('DATE')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.date && !arabicaFormData.date && <small className="error-message">{t('DATE_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="antwerp"
                  name="antwerp"
                  value={arabicaFormData.antwerp}
                  className={`input-field ${touchedFields.antwerp && !arabicaFormData.antwerp ? 'error' : ''}`}
                  onChange={(event) => {
                    handleChange('antwerp', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('antwerp')
                  }}
                />
                <label htmlFor="antwerp" className={`field-label ${touchedFields.antwerp && !arabicaFormData.antwerp ? 'error' : ''}`}>
                  {t('ANTWERP')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.antwerp && !arabicaFormData.antwerp && <small className="error-message">{t('ANTWERP_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="barcelona"
                  name="barcelona"
                  value={arabicaFormData.barcelona}
                  onChange={(event) => {
                    handleChange('barcelona', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('barcelona')
                  }}
                  className={`input-field ${touchedFields.barcelona && !arabicaFormData.barcelona ? 'error' : ''}`}
                />
                <label htmlFor="compareToPreDay" className={`field-label ${touchedFields.barcelona && !arabicaFormData.barcelona ? 'error' : ''}`}>
                  {t('BARCELONA')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.barcelona && !arabicaFormData.barcelona && <small className="error-message">{t('BARCELONA_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="hamburg"
                  name="hamburg"
                  value={arabicaFormData.hamburg}
                  onChange={(event) => {
                    handleChange('hamburg', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('hamburg')
                  }}
                  className={`input-field ${touchedFields.hamburg && !arabicaFormData.hamburg ? 'error' : ''}`}
                />
                <label htmlFor="nonTenderable" className={`field-label ${touchedFields.hamburg && !arabicaFormData.hamburg ? 'error' : ''}`}>
                  {t('HAMBURG')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.hamburg && !arabicaFormData.hamburg && <small className="error-message">{t('HAMBURG_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="houston"
                  name="houston"
                  value={arabicaFormData.houston}
                  onChange={(event) => {
                    handleChange('houston', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('houston')
                  }}
                  className={`input-field ${touchedFields.houston && !arabicaFormData.houston ? 'error' : ''}`}
                />
                <label htmlFor="houston" className={`field-label ${touchedFields.houston && !arabicaFormData.houston ? 'error' : ''}`}>
                  {t('HOUSTON')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.houston && !arabicaFormData.houston && <small className="error-message">{t('HOUSTON_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="newOrleans"
                  name="newOrleans"
                  value={arabicaFormData.newOrleans}
                  onChange={(event) => {
                    handleChange('newOrleans', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('newOrleans')
                  }}
                  className={`input-field ${touchedFields.newOrleans && !arabicaFormData.newOrleans ? 'error' : ''}`}
                />
                <label htmlFor="newOrleans" className={`field-label ${touchedFields.newOrleans && !arabicaFormData.newOrleans ? 'error' : ''}`}>
                  {t('NEWORLEANS')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.newOrleans && !arabicaFormData.newOrleans && <small className="error-message">{t('NEW_ORLEANS_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="newYork"
                  name="newYork"
                  value={arabicaFormData.newYork}
                  onChange={(event) => {
                    handleChange('newYork', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('newYork')
                  }}
                  className={`input-field ${touchedFields.newYork && !arabicaFormData.newYork ? 'error' : ''}`}
                />
                <label htmlFor="newYork" className={`field-label ${touchedFields.newYork && !arabicaFormData.newYork ? 'error' : ''}`}>
                  {t('NEW_YORK')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.newYork && !arabicaFormData.newYork && <small className="error-message">{t('NEW_YORK_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="virginia"
                  name="virginia"
                  value={arabicaFormData.virginia}
                  onChange={(event) => {
                    handleChange('virginia', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('virginia')
                  }}
                  className={`input-field ${touchedFields.virginia && !arabicaFormData.virginia ? 'error' : ''}`}
                />
                <label htmlFor="virginia" className={`field-label ${touchedFields.virginia && !arabicaFormData.virginia ? 'error' : ''}`}>
                  {t('VIRGINIA')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.virginia && !arabicaFormData.virginia && <small className="error-message">{t('VIRGINIA_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="miami"
                  name="miami"
                  value={arabicaFormData.miami}
                  onChange={(event) => {
                    handleChange('miami', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('miami')
                  }}
                  className={`input-field ${touchedFields.miami && !arabicaFormData.miami ? 'error' : ''}`}
                />
                <label htmlFor="miami" className={`field-label ${touchedFields.miami && !arabicaFormData.miami ? 'error' : ''}`}>
                  {t('MIAMI')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.miami && !arabicaFormData.miami && <small className="error-message">{t('MIAMI_ERROR_MESSAGE')}</small>}
            </div>

            <div className="input-field-wrapper">
              <span className="p-float-label">
                <InputText
                  id="compareToPreDay"
                  name="compareToPreDay"
                  value={arabicaFormData.compareToPreDay}
                  onChange={(event) => {
                    handleChange('compareToPreDay', event.target.value)
                  }}
                  onBlur={() => {
                    handleBlur('compareToPreDay')
                  }}
                  className={`input-field ${touchedFields.compareToPreDay && !arabicaFormData.compareToPreDay ? 'error' : ''}`}
                />
                <label htmlFor="compareToPreDay" className={`field-label ${touchedFields.compareToPreDay && !arabicaFormData.compareToPreDay ? 'error' : ''}`}>
                  {t('COMPARE_TO_PRE_DAY')} <span className="required-text-field">*</span>
                </label>
              </span>
              {touchedFields.compareToPreDay && !arabicaFormData.compareToPreDay && <small className="error-message">{t('PRE_DAY_ERROR_MESSAGE')}</small>}
            </div>
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default AddCountryStockReport
