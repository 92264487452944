import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Field, Form } from 'react-final-form'
import { InputSwitch } from 'primereact/inputswitch'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { useTranslation } from 'react-i18next'
import { classNames } from 'primereact/utils'
import LayoutContainer from 'Components/LayoutComponent'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const EditUSDACoffeeReport = ({ setLoading }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const { tags: initialTag, title: initialTitle, content: initialContent, reportLink: initialReportLink, publishedDateInUTC: initialPublishedDate, isUpcoming: initialIsUpcoming, formattedDate: initialFormattedDate, countryIdForEdit, idReport } = location.state

  const [initialValues, setInitialValues] = useState({
    idReport,
    tags: initialTag,
    title: initialTitle,
    content: initialContent,
    reportLink: initialReportLink,
    publishedDate: initialPublishedDate,
    countryId: countryIdForEdit,
    isUpcoming: initialIsUpcoming,
    formattedDate: new Date(`${initialPublishedDate}`)
  })

  const { tags, title, content, reportLink, publishedDate, formattedDate, countryId, isUpcoming } = initialValues

  const isButtonDisabled = !tags || !title || !reportLink || !publishedDate || !content
  const [dialogState, setDialogState] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => editUSDACoffeeReportData(), onDismiss: () => handleDismiss(), onSuccess: () => handleSuccess() })

  const editUSDACoffeeReportData = async () => {
    setLoading(true)

    try {
      const { formattedDate, ...updatedPayload } = initialValues
      const response = await apiAdapterCoffeeWeb.updateUSDACoffeeReport(updatedPayload)

      if (response.data.isSuccess) {
        setDialogState({
          ...dialogState,
          showModal: true,
          modalType: 'success',
          message: 'POST_USDA_REPORT_DATA_SUCCESS',
          onHide: () => handleSuccess()
        })
      }
    } catch (error) {
      setDialogState({
        ...dialogState,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
        onHide: () => handleDismiss()
      })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = (data) => {
    setInitialValues(data)
  }
  const validate = (data) => {
    const errors = {}

    const { tags, reportLink, title } = data

    if (!tags) {
      errors.tags = t('TAG_IS_REQUIRED')
    }

    if (!title) {
      errors.title = t('TITLE_IS_REQUIRED')
    }

    if (!content) {
      errors.content = t('CONTENT_IS_REQUIRED')
    }

    if (!reportLink) {
      errors.reportLink = t('REPORT_LINK_IS_REQUIRED')
    }

    if (!publishedDate) {
      errors.publishedDate = t('DATE_IS_REQUIRED')
    }

    return errors
  }

  const handleSubmit = () => {
    editUSDACoffeeReportData()
  }

  const handleDismiss = () => {
    setDialogState({ ...dialogState, showModal: false })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listUSDACoffeeReport)
  }

  const handleCountryChange = (countryId) => {
    setInitialValues((previousData) => ({
      ...previousData,
      countryId
    }))
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target

    setInitialValues({ ...initialValues, [name]: value })
  }

  const handleDateChange = (event) => {
    const currentDate = event.value

    const date = new Date(currentDate)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    const utcDate = new Date(Date.UTC(year, month, day))

    const isoStringAdjusted = utcDate.toISOString()

    setInitialValues((previousData) => ({
      ...previousData,
      formattedDate: currentDate,
      publishedDate: isoStringAdjusted
    }))
  }

  const toggleButton = () => {
    setInitialValues({ ...initialValues, isUpcoming: !isUpcoming })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  console.log(initialValues)

  return (
    <LayoutContainer title={'EDIT_USDA_COFFEE_REPORT'} breadCrumbsTitle={'editUSDACoffeeReport'}>
      <div className="add-usda-report-container" data-testid="add-usda-form">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="toggle-button-container">
                <label htmlFor="toggleButton" className="toggle-button">
                  {t('IS_UPCOMING')}
                </label>
                <div className="input-switch-wrapper">
                  <InputSwitch checked={initialValues?.isUpcoming} onChange={toggleButton} />
                </div>
              </div>
              <div className="field">
                <CountrySelector isFormComponent={true} countryId={countryId} selectCurrency={handleCountryChange} />
              </div>

              <Field
                name="tags"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="tags" {...input} value={initialValues.tags} onChange={handleInputChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="languageName" />
                      <label htmlFor="tags" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('TAG')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="title"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="title" disabled={isUpcoming} {...input} value={initialValues.title} onChange={handleInputChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="title" />
                      <label htmlFor="title" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('TITLE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <Field
                name="content"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputTextarea disabled={isUpcoming} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} {...input} type="text" name="content" onChange={handleInputChange} value={initialValues.content} rows={3} placeholder={t('ENTER_REPORT_CONTENT')} id="content" data-testid="content" />
                      <label htmlFor="content" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                        {t('CONTENT')}
                        <span className="required-field"> *</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <Field
                name="reportLink"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="reportLink" {...input} disabled={isUpcoming} value={initialValues.reportLink} onChange={handleInputChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="reportLink" />
                      <label htmlFor="reportLink" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('REPORT_LINK')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <Field
                name="publishedDate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="publishedDate" name="publishedDate" value={formattedDate} minDate={new Date()} onChange={(event) => handleDateChange(event)} showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="publishedDate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('PUBLISH_DATE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
            </form>
          )}
        />

        <div className="button-container">
          <ButtonComponent type={'submit'} disabled={isButtonDisabled} onClick={handleSubmit} />
        </div>
      </div>
      <DialogModal {...dialogState} />
    </LayoutContainer>
  )
}

export default Loader(EditUSDACoffeeReport)
