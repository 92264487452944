import { configUrl } from './configuration/index'
import coffeeWebApiService from './apiServiceCoffeeWeb'

const apiAdapterCoffeeWeb = {
  // AppSettings
  appSetting(generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/AppSetting`, generatedToken, callback)
  },
  updateAppSettings(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/AppSetting/UpdateAppSettings`, null, body, callback)
  },
  getFirebaseSettingsData(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/AppSetting/GetFirebaseSettings`, null, callback)
  },
  updateFirebaseSettings(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/AppSetting/UpdateFirebaseSettings`, null, body, callback)
  },
  updateCoffeeQuotesData(Numbers, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/AppSetting/UpdateCoffeeQuotesAvailable?Numbers=${Numbers}`, null, callback)
  },
  updateWorkbookData(Numbers, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/AppSetting/UpdateWorkbookAvailable?Numbers=${Numbers}`, null, callback)
  },

  // Coffee-News
  insertNewsFeed(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/news/InsertNewsFeed`, null, body, callback)
  },
  getAllNewsAndMediaWithPagination({ pageNumber, pageSize }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetAllNewsAndMediaWithPagination/${pageNumber}/${pageSize}`, null, callback)
  },

  deleteNews({ newsId, userId, reasonToDelete }, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/news/deletenews/${newsId}/${userId}/${reasonToDelete}`, null, callback)
  },
  NewsFeedApproveProcessForAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/news/NewsFeedApproveProcessForAdmin`, null, body, callback)
  },
  NewsFeedApproveProcessForSuperAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/news/NewsFeedApproveProcessForSuperAdmin`, null, body, callback)
  },
  convertToBase64(previewpdf, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/ConvertToBase64?filePath=${previewpdf}`, null, callback)
  },
  getAllDefaultImages(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetAllDefaultImages`, null, callback)
  },
  getTrendingNewsList(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetTrendingNewsList`, null, callback)
  },

  getNewsAndMediaById(newsId, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetNewsAndMediaById/${newsId}`, null, callback)
  },
  deleteNewsMedia(newsId, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/news/DeleteNewsMedia/${newsId}`, null, callback)
  },

  activeAndInActiveTrendingNews({ id, IsActive, hours }, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/news/ActiveAndInactiveProcessForTrendingNws/${id}/${IsActive}/${hours}`, null, null, callback)
  },
  getAllNewsType(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetAllNewsType`, null, callback)
  },
  getFilterNews(newsFilter, language, pageNumber, rowCount, searchQuery, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/news/GetFilterNews/${newsFilter}/${language}/${pageNumber}/${rowCount}?Search=${searchQuery}`, null, callback)
  },

  // EMAIL
  sendOTPEmail(email, body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Email/SendOTPEmail?emailid=${email}`, null, body, callback)
  },
  requestOTPForEditNews({ email, newsId }, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Email/RequestOTPForEditNews/${email}/${newsId}`, null, callback)
  },
  forgotPasswordLink({ email }, token, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Email/ForgotPasswordLink/${email}`, token, callback)
  },

  // FreightRates

  postInsertFreightRatesAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/FreightRates/InsertFreightRatesAdmin`, null, body, callback)
  },

  // GlobalDifferentials
  getGlobalDifferentialForAdmin(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalDifferential/GetGlobalDifferentialByCountryForEdit/${id}`, null, callback)
  },
  postInsertGlobalDifferentialAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalDifferential/InsertGlobalDifferentialAdmin`, null, body, callback)
  },
  apporveGlobalDifferenceForAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalDifferential/ApporveGlobalDifferenceForAdmin`, null, body, callback)
  },
  apporveGlobalDifferenceForSuperAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalDifferential/ApporveGlobalDifferenceForSuperAdmin`, null, body, callback)
  },
  getGlobalDifferentialDataForAdmin(idCountry, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalDifferential/GetGlobalDifferentialDataForAdmin/${idCountry}`, null, callback)
  },
  getGlobalDifferentialDataForSuperAdmin(idCountry, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalDifferential/GetGlobalDifferentialDataForSuperAdmin/${idCountry}`, null, callback)
  },
  rejectGlobalDifferentialData(IdCountry, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/GlobalDifferential/RejectGlobalDifferentialdata/${IdCountry}`, null, callback)
  },

  // GlobalRawCoffeePrice
  postInsertCoffeePriceAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice/InsertCoffeePriceAdmin`, null, body, callback)
  },
  getGlobalRawCoffeePriceOrderByCurrencyForAdmin({ id }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice/GetGlobalRawCoffeePriceOrderByCurrencyForAdmin/${id}`, null, callback)
  },
  postApproveCoffeePriceAdmin(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice/ApporveCoffeePriceAdmin`, null, body, callback)
  },
  postSuperAdminApproveRawCoffeePrice(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice/SuperAdminApproveRawCoffeePrice`, null, body, callback)
  },

  // LanguageMaster
  getAllLanguages(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/LanguageMaster/GetAllLanguages`, null, callback)
  },
  postLanguages(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/LanguageMaster/PostLanguages`, null, body, callback)
  },
  getAllLanguageList(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/LanguageMaster/GetLanguageForCounrty`, null, callback)
  },

  UpdateLanguage(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/LanguageMaster/UpdateLanguage`, null, body, callback)
  },
  activateLanguage(idLanguage, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/LanguageMaster/ActiveLanguage/${idLanguage}`, null, callback)
  },
  deactivateLanguage(idLanguage, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/LanguageMaster/InActiveLanguage/${idLanguage}`, null, callback)
  },
  languageForNews(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/LanguageMaster/LanguageForNews`, null, callback)
  },
  activateLanguageForNews(idLanguage, isActive, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/LanguageMaster/ActiveLanguageForNews/${idLanguage}/${isActive}`, null, callback)
  },

  // Market
  getAllMarketName(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/Market`, null, callback)
  },

  // Menu Master
  insertMenuGroup(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/menumaster/InsertMenuGroup`, null, body, callback)
  },

  getAllMenu(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/menumaster/getallmenu`, null, callback)
  },
  GetMenuByMenuIds(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/menumaster/GetMenuByMenuIds/${id}`, null, callback)
  },
  getMenuGroupBySubscriptionType(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/menumaster/GetMenuGroupBySubscriptionType/${id}`, null, callback)
  },
  putMenuMaster(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/PutMenuMaster`, null, body, callback)
  },
  insertMenu(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/menumaster/InsertMenu`, null, body, callback)
  },
  getCoffeeMenu(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/menumaster/GetAllActiveMenuList`, null, callback)
  },
  activeMenu(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/ActiveMenu/${id}`, null, callback)
  },
  deactivateMenu(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/DeactiveMenu/${id}`, null, callback)
  },
  activateMenuForWeb(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/ActiveMenuForWeb/${id}`, null, callback)
  },
  deactivateMenuForWeb(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/DeactiveMenuForWeb/${id}`, null, callback)
  },
  activateMenuForMobile(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/ActiveMenuForMobile/${id}`, null, callback)
  },
  deactivateMenuForMobile(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/DeactiveMenuForMobile/${id}`, null, callback)
  },
  activateMenuLaunch(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/ActiveMenuLanch/${id}`, null, callback)
  },
  deactivateMenuLaunch(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/menumaster/DeactiveMenuLanch/${id}`, null, callback)
  },

  // MiscMaster
  getAllCountries(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/GetAllCountries`, null, callback)
  },
  getStateList(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/miscmaster/GetStateList/102`, null, callback)
  },

  // MobileUpdate
  getAllMobileUpdates(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/MobileUpdate/GetAllMobileUpdates`, null, callback)
  },
  addMobile(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/MobileUpdate/PostMobileUpdate`, null, body, callback)
  },
  toggleMobileUpdateStatus({ userId, activeAndInactive }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/MobileUpdate/ActiveAndInactiveMobileupdates/${userId}/${activeAndInactive}`, null, body, callback)
  },
  ActiveAndInactiveIsForceUpdate({ userId, activeAndInactive }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/MobileUpdate/ActiveAndInactiveForceFullUpdates/${userId}/${activeAndInactive}`, null, body, callback)
  },

  // OccupationType

  occupationType(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/OccupationType`, null, callback)
  },

  // PasswordService

  updateUserPassword({ userId, password }, token, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/passwordservice/updateuserpassword/${userId}/${password}`, token, callback)
  },
  changePassword({ userid, password, CurrentPassword }, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/passwordservice/ChangePassword/${userid}/${password}/${CurrentPassword}`, null, null, callback)
  },
  updatePasswordWithEncryptedUserId({ encryptUserId, password }, token, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/passwordservice/updatePasswordWithEncryptedUserId/${encryptUserId}/${password}`, token, callback)
  },

  // PaymentMapping

  getAllPaymentCountry(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/PaymentCountryMapping/GetAllPaymentCountryMapping`, null, callback)
  },
  postPaymentCountry(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/PaymentCountryMapping/PostPaymentCountryMapping`, null, body, callback)
  },
  activeAndInactivePayment({ userId, activeAndInactive }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/PaymentCountryMapping/ActiveAndInactivePayment/${userId}/${activeAndInactive}`, null, body, callback)
  },
  getAllPaymentType(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/PaymentMaster/GetAllPaymentTypes`, null, callback)
  },
  createNewPayment(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/PaymentMaster/PostPaymentType`, null, body, callback)
  },
  updatePaymentData(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/PaymentMaster/PutPaymentType`, null, body, callback)
  },
  deleteInActiveMaster({ toggleId }, callback, idpayment, token) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/PaymentMaster/DeletePaymentType/${toggleId}`, idpayment, token, callback)
  },

  activatePayment({ toggleId }, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/PaymentMaster/ActivatePaymentType/${toggleId}`, null, callback)
  },

  //  Payment Report
  getAllPaymentReport(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/Order/GetAllPaymentReports`, null, callback)
  },

  getInvoiceNumberContent(invoiceNumber, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/Order/ReGenerateInvoiceForUser?Invoicenumb=${invoiceNumber}`, null, callback)
  },

  postReconcileFormData(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/ReconcileForm/PostReconcileForm`, null, body, callback)
  },

  getReconciledReport(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/ReconcileForm/GetAllReconcileForm`, null, callback)
  },

  // RoleMaster
  getAllRoles(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/RoleMaster/GetAllRoles`, null, callback)
  },

  postRoleMasters(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/RoleMaster/AddRoles`, null, body, callback)
  },

  // RoleMenuMapping

  postRoleMenuData(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/RoleMenuMapping/PostRoleMenuData`, null, body, callback)
  },

  getMenuOnRoleId(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/RoleMenuMapping/GetMenuOnRoleid/${id}`, null, callback)
  },

  // S3Bucket
  uploadFileToS3Bucket(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/S3Bucket/UploadFileToS3Bucket`, null, body, callback)
  },

  // ScreenMaster

  createNewScreen(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/ScreenMaster/PostScreens`, null, body, callback)
  },

  // SMSService
  sendOtpWithTemplate(number, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SMSService/sendotpwithtemplate/${number}`, null, callback)
  },

  verifyOtp({ sessionId, otp }, body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/SMSService/verifyotp/${sessionId}/${otp}`, null, body, callback)
  },

  // SubscriptionFeatureMaster

  getSubscriptionFeatureMaster(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionFeatureMaster/GetSubscriptionFeatureMaster`, null, callback)
  },

  // SubscriptionMaster

  getSubscriptionTypes(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionMaster/GetSubscriptionTypes`, null, callback)
  },

  getAllSubscriptionTypes(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionMaster/GetAllSubscriptionTypes`, null, callback)
  },
  postSubscriptionType(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/SubscriptionMaster/PostSubscriptionType`, null, body, callback)
  },
  putSubscriptionType(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/SubscriptionMaster/PutSubscriptionType`, null, body, callback)
  },
  activateSubscription({ toggleId }, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/SubscriptionMaster/ActivateSubscription/${toggleId}`, null, callback)
  },
  deactivateSubscription({ toggleId }, callback, id, token) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/SubscriptionMaster/DeleteSubscriptionType/${toggleId}`, id, token, callback)
  },
  getSubscriptionMaster(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionMaster/GetSubscriptionMaster`, null, callback)
  },

  getSubscriptionDetailsId(SubscriptionType, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionMaster/GetSubscriptionDetails/${SubscriptionType}`, null, callback)
  },
  postSubscriptionFeatures(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/SubscriptionMaster/PostSubscriptionFeatures`, null, body, callback)
  },

  // SubscriptionPaymentMapping

  getAllSubscriptionPaymentMapping(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/GetAllSubscriptionPaymentMapping`, null, callback)
  },
  getSubscriptionPaymentMappingById(Id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/GetSubscriptionPaymentMappingById/${Id}`, null, callback)
  },

  getSubscriptionPaymentMappingDataById(idsubscription, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/GetAllSubscriptionPaymentMappingById/${idsubscription}`, null, callback)
  },
  postSubscriptionPaymentMapping(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/PostSubscriptionPaymentMapping`, null, body, callback)
  },
  activateSubscriptionMapping(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/ActiveSubscriptionPaymentMapping/${id}`, null, callback)
  },
  deactivateSubscriptionMapping(id, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/InActiveSubscriptionPaymentMapping/${id}`, null, callback)
  },
  updateSubscriptionPayment(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/SubscriptionPaymentMapping/PutSubscriptionPaymentMapping`, null, body, callback)
  },

  //    ------------------------- TerminalDetails Endpoints -------------------------

  // get all the active terminals
  getAllTerminalDetails(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrlForCoffeeQuotes}/api/TerminalDetails/GetTerminalDetails`, null, callback)
  },
  // get the indidual terminal details
  getTerminalDetails(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrlForCoffeeQuotes}/api/TerminalDetails/${id}`, null, callback)
  },
  // add the new terminal in the database
  terminalDetails(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrlForCoffeeQuotes}/api/TerminalDetails`, null, body, callback)
  },
  // edit the terminal details
  putTerminalDetail(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrlForCoffeeQuotes}/api/TerminalDetails/PutTerminalDetail`, null, body, callback)
  },
  // make active in-active the terminal
  toggleTerminalDetailsStatus({ userId }, body, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrlForCoffeeQuotes}/api/TerminalDetails/InActiveTerminalDetails/${userId}`, null, body, callback)
  },

  //    ------------------------- Terminal Data Endpoint -------------------------
  // post the terminal history data, for the perticulat terminal
  insertTerminalData(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrlForCoffeeQuotes}/api/Terminaldata/PostExcelTerminalData`, null, body, callback)
  },

  // UserService
  authenticateAdminUser(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/Authenticate`, null, body, callback)
  },
  authenticateByEmailOtp(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/AuthenticateByEmailOtp`, null, body, callback)
  },
  postCreateUser(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/createuser`, null, body, callback)
  },
  updateOnlyUser(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/UpdateOnlyUser`, null, body, callback)
  },
  putDeactivateAccount({ id, userId }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/DeactivateAccount/${id}/${userId}`, null, body, callback)
  },
  putActivateAccount({ id }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/ActivateAccount/${id}`, null, body, callback)
  },
  generateAuthToken(body, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/generateauthtoken`, body, callback)
  },
  getAllClientUsers(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/getallcleintusers`, null, callback)
  },
  getAllOtherUsers(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/getallotherusers`, null, callback)
  },
  getUserByUserId(id, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/getuserbyuserid/${id}/${true}`, null, callback)
  },
  getAdminUserByPhoneNumber({ phone, countryId }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/GetAdminUserByPhoneNumber/${phone}/${countryId}`, null, callback)
  },
  isEmailExist({ email }, token, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/isemailexist/${email}`, token, callback)
  },

  userLogout(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/UserLogout`, null, body, callback)
  },
  updateUserProfileSettings({ userid, UserDateFormat, UserTimeFormat, UserLanguage, UserLandingPageUrl, UserTimeZone, UpdateFrom, ShowWebNotification, ShowMobileNotification }, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/userservice/UpdateUserProfileSettings/${userid}/${UserDateFormat}/${UserTimeFormat}/${UserLanguage}/${UserLandingPageUrl}/${UserTimeZone}/${UpdateFrom}/${ShowMobileNotification}/${ShowWebNotification}`, null, null, callback)
  },

  getProfileSettingMasters(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/GetProfileSettingMasters`, null, callback)
  },
  deleteAccount(id, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/userservice/DeleteAccount/${id}`, null, callback)
  },

  updateOnlyAdmin(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/userservice/UpdateOnlyAdmin`, null, body, callback)
  },

  isPhoneExist({ phoneNo, countryPhoneCode }, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/isphoneexist?Phone=${phoneNo}&UserId=0&CountryPhoneCode=${countryPhoneCode}`, null, callback)
  },
  makeFreeNews({ newsId, isFree }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/news/makeFreeNews/${newsId}/${isFree}`, null, body, callback)
  },
  makeFullFreeNews({ newsId, isFree }, body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/news/makeFullFreeNews/${newsId}/${isFree}`, null, body, callback)
  },

  // User stats
  getAllUserListReport(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/Report/getAllUserListReport`, null, callback)
  },

  // GetCoffeeQuotesDataFromHttpURL
  getICEMartketTerminalDataFromHttpURL(callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrlForCoffeeQuotes}/api/IceMarketPublish/GetICEMartketTerminalData`, null, callback)
  },

  // put ICE Market data when the market is OFF
  putICEMarketTerminalData(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrlForCoffeeQuotes}/api/IceMarketPublish/PutICEMartketTerminalData`, null, body, callback)
  },

  // Coffee Report

  getDataByReportName({ reportName }, generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/S3Bucket/GetDataByReportName?ReportName=${reportName}`, generatedToken, callback)
  },

  // USDA Coffee Report
  postUSDACoffeeReport(body, callback) {
    return coffeeWebApiService.post(`${configUrl.baseUrl}/api/USDACoffeeReport/postUSDACoffeeReport`, null, body, callback)
  },
  updateUSDACoffeeReport(body, callback) {
    return coffeeWebApiService.put(`${configUrl.baseUrl}/api/USDACoffeeReport/updateUSDACoffeeReport`, null, body, callback)
  },
  getUSDACoffeeReport(generatedToken, callback) {
    return coffeeWebApiService.get(`${configUrl.baseUrl}/api/USDACoffeeReport/getUSDACoffeeReport`, generatedToken, callback)
  },
  deleteUSDACoffeeReport(idReport, callback) {
    return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/USDACoffeeReport/deleteUSDACoffeeReport/${idReport}`, null, callback)
  }

  // BELOW API'S ARE USED IN COMMENTED CODE-(PriceUpload,AddMapping,EditMapping,ScreenLanguage,EditScreen,CompanyApproved,CoffeePrice,CoffeePriceUpload,CoffeePriceEntry,FreightRatesEntry,ScreenControlMapping):

  // getCompanyApproval(token, callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl}/api/userservice/GetAlluserCompanyList`, token, callback)
  // },
  // getAllScreens(callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl}/api/ScreenMaster/GetAllScreens`, null, callback)
  // },
  // updateScreen(body, callback) {
  //   return coffeeWebApiService.put(`${configUrl.baseUrl}/api/ScreenMaster/PutScreens`, null, body, callback)
  // },
  //   getApiForUserId(companyId, token, callback) {
  //     return coffeeWebApiService.get(`${configUrl.baseUrl2}/api/ComapanyMaster/GetCompanyByUserId/${companyId}`, token, callback)
  //   },
  //    companyApproveProcessForAdmin(body, callback) {
  //     return coffeeWebApiService.post(`${configUrl.baseUrl2}/api/ComapanyMaster/UserCompanyApproveProcessForAdmin`, null, body, callback)
  //   },
  //   companyApproveProcessForAdminReject(body, callback) {
  //     return coffeeWebApiService.post(`${configUrl.baseUrl2}/api/ComapanyMaster/UserCompanyApproveProcessForAdmin`, null, body, callback)
  //   },
  //   approveForAdminForCoffeePrice(body, callback) {
  //     return coffeeWebApiService.post(`${configUrl.baseUrl2}/api/CoffeePrice/UserCompanyApproveProcessForAdmin`, null, body, callback)
  //   },
  //   rejectForAdmin(body, callback) {
  //     return coffeeWebApiService.post(`${configUrl.baseUrl2}/api/CoffeePrice/UserCompanyApproveProcessForAdmin`, null, body, callback)
  //   },

  // getPriceData(token, callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl2}/api/CoffeePrice/GetAllCoffePriceList`, token, callback)
  // },
  // openPreviewModal(userId, callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl2}/api/CoffeePrice/GetCoffePriceWithCompalyDetaillList/${userId}`, null, callback)
  // },
  //   getCoffeePriceForEdit(callback) {
  //     return coffeeWebApiService.get(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice/GetGlobalRawCoffeePriceOrderByCurrencyForEdit`, null, callback)
  //   },
  //   postGlobalRawCoffeePriceInBulk(body, callback) {
  //     return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice/PostGlobalRawCoffeePriceInBulk`, null, body, callback)
  //   },

  //   getGlobalFreightRates(callback) {
  //     return coffeeWebApiService.get(`${configUrl.baseUrl}/api/coffee/getfreightitems/`, null, callback)
  //   },

  //   addRawCoffeePrice(body, callback) {
  //     return coffeeWebApiService.post(`${configUrl.baseUrl}/api/GlobalRawCoffeePrice`, null, body, callback)
  //   },
  // deleteFrightRatePort({ loadingPort, idCountry }, callback) {
  //   return coffeeWebApiService.delete(`${configUrl.baseUrl}/api/coffee/DeleteLoadingPort/${loadingPort}/${idCountry}`, null, callback)
  // },

  // getScreenControlMapping(callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl}/api/ScreenControlMapping/GetScreenControlMapping`, null, callback)
  // },

  // getScreenControlMappingBasedOnLanguage({ screenid, languageid }, callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl}/api/ScreenControlMapping/GetScreenControlMappingBasedOnLanguage/${screenid}/${languageid}`, null, callback)
  // },
  // postScreenControlMapping(body, callback) {
  //   return coffeeWebApiService.post(`${configUrl.baseUrl}/api/ScreenControlMapping/PostScreenControlMapping`, null, body, callback)
  // },
  // putScreenControlMapping(body, callback) {
  //   return coffeeWebApiService.put(`${configUrl.baseUrl}/api/ScreenControlMapping/PutScreenControlMapping`, null, body, callback)
  // },

  // getCoffeeQualityMaster(callback) {
  //   return coffeeWebApiService.get(`${configUrl.baseUrl}/api/CoffeeQualityMaster/GetCoffeeQualityMasterListFor`, null, callback)
  // },
}

export default apiAdapterCoffeeWeb
