import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Dropdown } from 'primereact/dropdown'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { commonMethods } from 'Utils/commonMethods'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ListSubscriptionPaymentMapping = ({ setLoading, loadingMock, subscriptionMock, submitButtonDisabledMock, subscriptionTypesMock, paymentTypesMock }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { id } = useParams()
  const location = useLocation()
  const params = new URLSearchParams()

  const [subscription, setSubscription] = useState([])
  const [subscriptionTypes, setSubscriptionTypes] = useState([])
  const [paymentTypes, setSubPaymentIdTypes] = useState([])
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [modalState, setModalState] = useState({
    showModal: false,
    modalType: '',
    message: '',
    onRetry: () => {},
    onHide: () => {}
  })

  useEffect(() => {
    init()
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (subscriptionMock) {
      setLoading(loadingMock)
      setSubscription(subscriptionMock)
      setSubmitButtonDisabled(submitButtonDisabledMock)
      setSubscriptionTypes(subscriptionTypesMock)
      setSubPaymentIdTypes(paymentTypesMock)
    }
  })

  const init = async () => {
    setLoading(true)
    const fetchAllSubscriptions = apiAdapterCoffeeWeb.getSubscriptionMaster()
    const fetchAllPayments = apiAdapterCoffeeWeb.getAllPaymentType()
    const fetchSubscriptionPaymentMappingById = apiAdapterCoffeeWeb.getSubscriptionPaymentMappingById(id)

    try {
      const [fetchAllSubscriptionsResponse, fetchAllPaymentsResponse, fetchSubscriptionPaymentMappingByIdResponse] = await Promise.all([fetchAllSubscriptions, fetchAllPayments, fetchSubscriptionPaymentMappingById])

      setSubscriptionTypes(fetchAllSubscriptionsResponse.data.returnLst)
      setSubPaymentIdTypes(fetchAllPaymentsResponse.data.returnLst)
      setSubscription(fetchSubscriptionPaymentMappingByIdResponse.data.returnLst)
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        onHide: handleReload,
        onRetry: handleReload
      })
    } finally {
      setLoading(false)
    }
  }

  const editSubscriptionPaymentMapping = async () => {
    setLoading(true)
    handleDismiss()
    try {
      const data = {
        id: subscription.id,
        idSubscription: subscription.idSubscription,
        paymentId: subscription.paymentId
      }

      const response = await apiAdapterCoffeeWeb.updateSubscriptionPayment(data)

      if (response.data.statusCode === 201) {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'success',
          message: 'PAYMENT_MAPPING_UPDATED'
        })
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'success',
        message: 'FAILED_TO_UPDATE_PAYMENT_MAPPING',
        onRetry: editSubscriptionPaymentMapping
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDropdown = (event) => {
    const { name, value } = event.target

    if (name === 'subscriptionId') {
      setSubscription({ ...subscription, idSubscription: value })
    } else if (name === 'paymentId') {
      setSubscription({ ...subscription, paymentId: value })
    }
    setSubmitButtonDisabled(false)
  }

  const handleDismiss = () => {
    if (modalState.modalType === 'success') {
      handleSuccess()
    } else {
      setModalState({
        ...modalState,
        showModal: false
      })
    }
  }

  const handleSuccess = () => {
    params.set('tab', 'subscription_payment')
    history.push({ pathname: `${ROUTE_STRINGS.subscriptionConfig}`, search: params.toString(), state: location.state })
  }

  const onSubmit = (data) => {
    setSubscription(data)
  }

  const handleReload = () => {
    commonMethods.handleRetry
  }

  return (
    <>
      <LayoutContainer title={'EDIT_SUBSCRIPTION_PAYMENT_LIST'} breadCrumbsTitle={'editSubscriptionPaymentType'}>
        <div className="edit-subscription-payment-type" data-testid="editSubscriptionPayment-form">
          <Form
            onSubmit={onSubmit}
            initialValues={subscription}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="subscriptionId"
                  render={({ input }) => (
                    <div className="field">
                      <div className="dropdown">
                        <div className="p-float-label">
                          <Dropdown
                            {...input}
                            id="idSubscription"
                            name="subscriptionId"
                            value={subscriptionTypes.length && subscription?.id ? subscription.idSubscription : t('NO_DATA_FOUND_MESSAGE')}
                            options={subscriptionTypes
                              .filter((item) => item.isActive && item.basePrice > 0)
                              .map((item) => ({
                                label: item.name,
                                value: item.id
                              }))}
                            onChange={handleDropdown}
                            className="custom-hover-color"
                            placeholder={subscriptionTypes.length && subscription?.id ? t('SELECT_SUBSCRIPTION') : t('NO_DATA_FOUND_MESSAGE')}
                            data-testid="subscriptionNameDropdown"
                          />
                          <label htmlFor="idSubscription">
                            {t('SUBSCRIPTION_NAME')} <span className="star-required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                />
                <Field
                  name="paymentId"
                  render={({ input }) => (
                    <div className="field">
                      <div className="dropdown">
                        <div className="p-float-label">
                          <Dropdown
                            {...input}
                            id="paymentId"
                            name="paymentId"
                            value={paymentTypes.length && subscription?.id ? subscription.paymentId : t('NO_DATA_FOUND_MESSAGE')}
                            options={paymentTypes
                              .filter((item) => item.isActive)
                              .map((item) => ({
                                label: item.paymentName,
                                value: item.idPayment
                              }))}
                            onChange={handleDropdown}
                            className="custom-hover-color"
                            placeholder={paymentTypes.length && subscription?.id ? t('SELECT_PAYMENT') : t('NO_DATA_FOUND_MESSAGE')}
                            data-testid="paymentNameDropdown"
                          />
                          <label htmlFor="paymentId">
                            {t('PAYMENT_NAME')} <span className="star-required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                />
                <div className="edit-subscription-payment-container-button">
                  <ButtonComponent type={'cancel'} onClick={handleSuccess} />
                  <ButtonComponent type={'submit'} onClick={editSubscriptionPaymentMapping} disabled={submitButtonDisabled} />
                </div>
              </form>
            )}
          />
        </div>
        <DialogModal {...modalState} onSuccess={handleSuccess} onHide={handleSuccess} onDismiss={handleDismiss} />
      </LayoutContainer>
    </>
  )
}

export default Loader(ListSubscriptionPaymentMapping)
