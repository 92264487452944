import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { InputTextarea } from 'primereact/inputtextarea'
import { ProgressSpinner } from 'primereact/progressspinner'
import { SelectButton } from 'primereact/selectbutton'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Image } from 'primereact/image'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { commonMethods } from 'Utils/commonMethods'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { getUserRoleBasedFeature } from 'Utils/RolesPermission'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const NewsReadMore = ({ setLoading, mockSelectedNewsData, mockOpenRemarkDialog, mockOpenApproveDialog, mockNotificationProcessDialog }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const paramsLanguageIdAndFilterNews = new URLSearchParams()
  const userDetails = getLocalUserDetails()
  const { id: userId } = userDetails
  const { id: newsId } = params
  const approvalOptions = [t('REJECT'), t('APPROVE')]

  const featureData = getUserRoleBasedFeature()
  const functionality = featureData?.functionality || {}
  const newsFeed = functionality?.newsFeed || {}
  const approveRejectButtonDisabled = newsFeed?.approveRejectButtonDisabled

  const [selectedNewsData, setSelectedNewsData] = useState({})
  const [hideNotification, setHideNotification] = useState('')
  const [viewButtonLabel, setViewButtonLabel] = useState('')
  const [selectedButtonValue, setSelectedButtonValue] = useState('')
  const [remarkInput, setRemarkInput] = useState('')
  const [selectedButton, setSelectedButton] = useState('')
  const [notificationProcessDialog, setNotificationProcessDialog] = useState(false)
  const [openRemarkDialog, setOpenRemarkDialog] = useState(false)
  const [openApproveDialog, setOpenApproveDialog] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [paramsFilterData, setParamsFilterData] = useState('')
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: '',
    onConfirmation: null,
    onRetry: null
  })

  const { nwsFeedMedia, newsDate, createdOn, publishedOn, publishedDate, author, views, newsHtml, subject, isApproved, languageId } = selectedNewsData

  useEffect(() => {
    getCoffeeNewsData()
  }, [])

  useEffect(() => {
    if (mockOpenRemarkDialog) {
      setSelectedNewsData(mockSelectedNewsData)
      setOpenRemarkDialog(mockOpenRemarkDialog)
      setOpenApproveDialog(mockOpenApproveDialog)
      setNotificationProcessDialog(mockNotificationProcessDialog)
    }
  })

  const getCoffeeNewsData = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getNewsAndMediaById(newsId)
      const newsData = response.data.returnLst[0]

      const { fileType, publishedOn, isPending, isAdmVerified, isSAVerified, isRejected, isApproved } = newsData

      setSelectedNewsData(newsData)
      setHideNotification(publishedOn)
      if (fileType === 'xlsx') {
        setViewButtonLabel(t('DOWNLOAD_DOCUMENT'))
      } else if (fileType === 'pdf') {
        setViewButtonLabel(t('VIEW_DOCUMENT'))
      }

      if (isPending) {
        setSelectedButtonValue('')
      } else if (isAdmVerified && userId === 2) {
        setSelectedButtonValue(t('APPROVE'))
      } else if (isSAVerified && userId === 1) {
        setSelectedButtonValue(t('APPROVE'))
      } else if (isApproved) {
        setSelectedButtonValue(t('APPROVE'))
      } else if (isRejected) {
        setSelectedButtonValue(t('REJECT'))
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error',
        onRetry: getCoffeeNewsData
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSuperAdminApproval = async (status, item) => {
    setOpenRemarkDialog(false)
    setLoading(true)

    const postData = {
      id: newsId,
      userid: userId,
      isSAVerified: status,
      saApproveDtm: new Date().toJSON(),
      sadmRejectRemark: status === true ? '' : remarkInput,
      sendNotification: hideNotification === '1900-01-01T00:00:00' ? true : isChecked
    }

    try {
      const approveStatus = await apiAdapterCoffeeWeb.NewsFeedApproveProcessForSuperAdmin(postData)

      const { status: statusCode } = approveStatus

      if (statusCode === 200) {
        setLoading(false)
        setOpenApproveDialog(false)
        setSelectedButtonValue(item)
        setNotificationProcessDialog(false)
        setModalState({
          showModal: true,
          message: status === true ? t('NEWS_APPROVED_SUCCESSFULLY') : t('NEWS_REJECTED_SUCCESSFULLY'),
          modalType: 'success',
          softAction: 'OK'
        })
      }
    } catch (error) {
      setOpenApproveDialog(false)
      setOpenRemarkDialog(false)
      setSelectedButtonValue(selectedButtonValue)
      setNotificationProcessDialog(false)
      setModalState({
        showModal: true,
        message: t('FAILED_TO_APPROVE_THE_NEWS'),
        modalType: 'error',
        onRetry: handleSuperAdminApproval
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAdminApproval = async (status, item) => {
    setOpenRemarkDialog(false)

    const postData = {
      id: newsId,
      isAdmVerified: status,
      admIdUser: userId,
      admApproveDtm: new Date().toJSON(),
      admRejectRemark: status === true ? '' : remarkInput
    }

    setLoading(true)

    try {
      const approveStatus = await apiAdapterCoffeeWeb.NewsFeedApproveProcessForAdmin(postData)

      const { status: statusCode } = approveStatus

      if (statusCode === 200) {
        setLoading(false)
        setSelectedButtonValue(item)
        setModalState({
          showModal: true,
          message: status === true ? t('NEWS_APPROVED_SUCCESSFULLY') : t('NEWS_REJECTED_SUCCESSFULLY'),
          modalType: 'success',
          softAction: 'OK'
        })
      }
    } catch (error) {
      setOpenRemarkDialog(false)
      setSelectedButtonValue(selectedButtonValue)
      setModalState({
        showModal: true,
        message: t('FAILED_TO_APPROVE_THE_NEWS'),
        modalType: 'error',
        onRetry: handleAdminApproval
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSelectButton = (e) => {
    const value = e.value

    if (value === t('APPROVE')) {
      if (userDetails.id !== 1) {
        setParamsFilterData('3')
      } else {
        setParamsFilterData('1')
      }

      if (userId === 2) {
        handleAdminApproval(true, value)
      } else {
        setSelectedButton(value)
        setOpenApproveDialog(true)
      }
    } else if (value === t('REJECT')) {
      setParamsFilterData('4')
      if (isApproved) {
        setModalState({
          showModal: true,
          message: 'ARE_YOU_SURE_YOU_WANT_TO_REJECT_THIS_NEWS',
          modalType: 'info',
          onConfirmation: openRejectRemarkDialog
        })
      }
    }
  }

  const rejectNews = () => {
    if (userId === 2) {
      handleAdminApproval(false, selectedButton)
    } else {
      handleSuperAdminApproval(false, selectedButton)
    }
  }

  const approveNewsAsSuperAdmin = (item) => {
    setOpenApproveDialog(false)
    setNotificationProcessDialog(true)
    handleSuperAdminApproval(true, item)
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const openRejectRemarkDialog = () => {
    onDismissFunction()
    setOpenRemarkDialog(true)
  }

  const onDismissFunction = () => {
    setModalState({ showModal: false })
    if (modalState.modalType === 'success') redirectPage()
  }

  const redirectPage = () => {
    paramsLanguageIdAndFilterNews.set('newsFilter', paramsFilterData)
    paramsLanguageIdAndFilterNews.set('language', languageId)
    history.push({
      pathname: ROUTE_STRINGS.listNewsFeed,
      search: paramsLanguageIdAndFilterNews.toString()
    })
  }

  const redirectPdfPage = (element) => {
    window.open(`${element.filePath}`, '_blank')
  }

  const onClickBackBtn = () => {
    history.goBack()
  }

  return (
    <LayoutContainer title={'USER_PREVIEW'} breadCrumbsTitle={'userPreview'} data-testId="breadcrumbs-title">
      {Object.keys(selectedNewsData).length > 0 && (
        <div className="news-user-preview-container">
          <div className="user-preview-image-wrapper">
            <dvi className="user-preview-action-button">
              <Button label={t('GO_BACK')} icon="pi pi-arrow-left" className="go-back-button" onClick={onClickBackBtn} />
              <SelectButton value={selectedButtonValue} unselectable onChange={(e) => handleSelectButton(e)} options={approvalOptions} disabled={approveRejectButtonDisabled} />
            </dvi>
            <div className="user-preview-images" data-testId="user-preview-image">
              {nwsFeedMedia.map((ele, index) => (ele.typeMedia === 'image' ? <Image key={index} src={ele.pathOfMedia} alt={ele.typeMedia} className="images-wrapper" preview /> : <video key={ele.id} src={ele.pathOfMedia} type={'video/mp4'} autoPlay={false} controls disablePictureInPicture controlsList="nodownload noplaybackrate" />))}
            </div>
          </div>
          <div className="user-preview-content-wrapper">
            <div className="content-banner">
              <div className="news-date-and-time">
                <div className="news-date" data-testId="news-published-date">
                  <i className="pi pi-calendar" data-testId="calender-icon"></i> {publishedDate === '1900-01-01' ? commonMethods.utcToLocalShortDate(newsDate) : commonMethods.utcToLocalShortDate(publishedDate)}
                </div>
                <div className="news-time" data-testId="news-published-time">
                  <i className="pi pi-clock" data-testId="clock-icon"></i> {publishedOn === '1900-01-01T00:00:00' ? commonMethods.utcToLocalOnlyTime(createdOn) : commonMethods.utcToLocalOnlyTime(publishedOn)}
                </div>
              </div>
              <div className="news-subject" data-testId="news-subjects">
                {ReactHtmlParser(subject)}
              </div>
              <div>
                <div className="news-author-and-view">
                  <div className="news-date" data-testId="news-author">
                    <i className="pi pi-user" data-testId="user-icon"></i> {ReactHtmlParser(author)}
                  </div>
                  <div className="news-time" data-testId="news-view">
                    <i className="pi pi-eye" data-testId="eye-icon"></i> {views}
                  </div>
                </div>
              </div>
            </div>
            {<div className="document-view-button">{viewButtonLabel && <Button label={viewButtonLabel} onClick={() => redirectPdfPage(selectedNewsData)} className="view-button" />}</div>}
            <div className="content-description-wrapper" data-testId="news-content">
              {ReactHtmlParser(newsHtml)}
            </div>
          </div>
        </div>
      )}

      <Dialog
        visible={openRemarkDialog}
        header={t('REJECT_REMARK')}
        onHide={() => setOpenRemarkDialog(false)}
        className="reject-remark-dialog"
        footer={
          <div className="reject-remark-dialog-footer">
            <Button label={t('CANCEL')} className="cancel-button" onClick={() => setOpenRemarkDialog(false)} />
            <Button label={t('SUBMIT')} className="submit-button" disabled={remarkInput === ''} onClick={() => rejectNews()} />
          </div>
        }
      >
        <InputTextarea value={remarkInput} onChange={(e) => setRemarkInput(e.target.value)} rows={5} cols={30} className="reject-remark-input" placeholder={t('ENTER_REMARK')} data-testId="remark-input-field" />
      </Dialog>

      <Dialog
        visible={openApproveDialog}
        header={t('CONFIRMATION')}
        onHide={() => setOpenApproveDialog(false)}
        className="news-approve-dialog"
        closable={false}
        footer={
          <div className="news-approve-dialog-footer">
            <Button label={t('NO')} className="neutral-button" onClick={() => setOpenApproveDialog(false)} />
            <Button label={t('YES')} className="confirm-action-button" onClick={() => approveNewsAsSuperAdmin(selectedButton)} />
          </div>
        }
      >
        <div className="dialog-content-message">{t('ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THIS_NEWS')}</div>

        {hideNotification === '1900-01-01T00:00:00' ? (
          ''
        ) : (
          <div className="check-box-container">
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} className="notification-check-box" />
            <span className="notification-label">{t('SEND_NOTIFICATION')}</span>
          </div>
        )}
      </Dialog>

      <Dialog visible={notificationProcessDialog} header={t('CONFIRMATION')} onHide={() => setNotificationProcessDialog(false)} className="news-notification-dialog" closable={false}>
        <div className="dialog-content-message">{t('NOTIFICATION_PROCESS')}</div>
        <ProgressSpinner strokeWidth="6" className="notification-spinner" />
      </Dialog>

      <DialogModal {...modalState} onDismiss={onDismissFunction} onSuccess={redirectPage} onHide={onDismissFunction} />
    </LayoutContainer>
  )
}

export default Loader(NewsReadMore)
