import './styles.scss'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { getLocalReconcileTableColumns, setLocalWithReconcileTableColumns } from 'Utils/LocalStorageHandler'
import { Messages } from 'primereact/messages'

const ReconciledReports = ({ setLoading }) => {
  const { t } = useTranslation()
  const msgs = useRef(null)

  const columns = [
    { dataField: 'idOrder', text: t('ORDER_ID'), className: 'order-id-column' },
    { dataField: 'invoiceNumber', text: t('INVOICE_NUMBER'), className: 'invoice-number-column' },
    { dataField: 'subscriptionName', text: t('SUBSCRIPTION_ID'), className: 'subscription-name-column' },
    { dataField: 'amount', text: t('TOTAL_AMOUNT'), className: 'total-amount-column' },
    { dataField: 'paymentStatus', text: t('PAYMENT_STATUS'), className: 'payment-status-column' },
    { dataField: 'paymentSource', text: t('PAYMENT_SOURCE'), className: 'payment-source-column' },
    { dataField: 'paymentId', text: t('PAYMENT_ID'), className: 'payment-id-column' },
    { dataField: 'paymentDate', text: t('PAYMENT_DATE'), className: 'payment-date-column' },
    { dataField: 'tallyDate', text: t('TALLY_DATE'), className: 'tally-date-column' },
    { dataField: 'utrNo', text: t('UTR_NO'), className: 'utr-no-column' },
    { dataField: 'settlementNo', text: t('SETTLEMENT_NO'), className: 'settlement-no-column' },
    { dataField: 'transactionDate', text: t('TRANSACTION_DATE'), className: 'transaction-date-column' },
    { dataField: 'grossAmount', text: t('GROSS_AMOUNT'), className: 'gross-amount-column' },
    { dataField: 'deductions', text: t('DEDUCTIONS'), className: 'deductions-column' },
    { dataField: 'netAmount', text: t('NET_AMOUNT'), className: 'net-amount-column' }
  ]

  const defaultColumnsVisibility = {
    idOrder: true,
    invoiceNumber: true,
    subscriptionName: true,
    amount: true,
    paymentStatus: true,
    paymentSource: true,
    paymentId: true,
    paymentDate: true,
    tallyDate: true,
    utrNo: true,
    settlementNo: true,
    transactionDate: true,
    grossAmount: true,
    deductions: true,
    netAmount: true
  }
  let activeCheckboxCount = 0

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [getReconciledReports, setGetReconciledReports] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [dialogState, setDialogState] = useState({ showModal: false, modalType: 'error', message: '', onHide: () => handleDismiss(), onRetry: () => handleRetry(), onDismiss: () => handleDismiss() })
  const [visible, setVisible] = useState(false)
  const [activeCheckBox, setActiveCheckBox] = useState(getLocalReconcileTableColumns() || defaultColumnsVisibility)
  const [disableButton, setDisableButton] = useState(false)

  const availableColumns = getLocalReconcileTableColumns() || activeCheckBox
  const rowCount = windowSize < TABLE_CONST.maxWidthTab ? TABLE_CONST.mobileRowCount : 5
  const paginationButtons = windowSize < TABLE_CONST.maxWidthTab ? TABLE_CONST.mobilePaginationButtonCount : TABLE_CONST.paginationButtonCount

  useEffect(() => {
    fetchReconciledReportData()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (msgs.current) {
      msgs.current.clear()
      msgs.current.show([{ sticky: true, severity: 'info', summary: 'Info : ', detail: t('YOU_NEED_TO_SELECT_MINIMUM_OF_4_COLUMNS'), closable: false }])
    }
  }, [disableButton])

  const fetchReconciledReportData = async () => {
    setLoading(true)
    setShowTableMessage(true)

    if (!getLocalReconcileTableColumns()) {
      setLocalWithReconcileTableColumns(availableColumns)
    }

    try {
      const response = await apiAdapterCoffeeWeb.getReconciledReport()

      const paymentDateResponse = response?.data?.returnLst

      if (paymentDateResponse?.length) {
        const modifiedData = paymentDateResponse.map((item) => {
          const paymentDate = commonMethods.utcToLocalTime(item.paymentDate)
          const transactionDate = commonMethods.utcToLocalTime(item.transactionDate)
          const tallyDate = commonMethods.utcToLocalTime(item.tallyDate)

          return {
            ...item,
            paymentDate,
            transactionDate,
            tallyDate
          }
        })

        setGetReconciledReports(modifiedData)
      } else {
        setDialogState({ ...dialogState, showModal: true, message: t('RECONCILED_REPORT_NO_DATA') })
      }
    } catch (error) {
      setDialogState({ ...dialogState, showModal: true, message: t('RECONCILED_REPORT_API_FAIL') })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const handleSettingsModalSubmit = () => {
    setVisible(false)
    setLocalWithReconcileTableColumns(activeCheckBox)
  }

  const handleSettingsModalClose = () => {
    setVisible(false)
    setActiveCheckBox(getLocalReconcileTableColumns())
  }

  const handleCheckboxChange = (column, e) => {
    const updatedActiveCheckbox = {
      ...activeCheckBox,
      [column.dataField]: e.checked
    }

    setActiveCheckBox(updatedActiveCheckbox)

    Object.keys(updatedActiveCheckbox).forEach((key) => {
      if (updatedActiveCheckbox[key]) {
        activeCheckboxCount += 1
      }
    })

    setDisableButton(activeCheckboxCount < 4)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleRetry = () => {
    setDialogState({ ...dialogState, showModal: false })
    fetchReconciledReportData()
  }

  const handleDismiss = () => setDialogState({ ...dialogState, showModal: false })

  const renderTableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="reconciled-report-container">
      <LayoutContainer title={'RECONCILED_REPORT'} breadCrumbsTitle={'reconciledReport'} showSettingsButton={true} setVisible={setVisible}>
        <div className="reconciled-report-table-wrapper">
          <DataTable value={getReconciledReports} paginator={getReconciledReports?.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={renderTableResponseMessage} className="reconciled-report-table">
            {columns
              .filter((column) => availableColumns[column.dataField])
              .map((column, index) => (
                <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} sortable={column.sortable} />
              ))}
          </DataTable>
        </div>
      </LayoutContainer>
      <DialogModal {...dialogState} />
      <Dialog header={t('SETTINGS')} visible={visible} className="setting-dialog-modal" onHide={() => handleSettingsModalClose()}>
        <div className="columns-container">
          {columns.map((column) => (
            <div key={column.dataField} className="column">
              <Checkbox inputId={column.dataField} name="column" value={column.dataField} checked={activeCheckBox[column.dataField]} onChange={(e) => handleCheckboxChange(column, e)} />
              <label htmlFor={column.dataField} className="column-name">
                {column.text}
              </label>
            </div>
          ))}
        </div>
        {disableButton && <Messages className="info-message" ref={msgs} />}
        <div className="setting-button-container">
          <div className={`submit-button-wrapper ${disableButton ? 'disable-text-present' : ''}`}>
            <ButtonComponent type="Custom" label="Submit" variant="action" disabled={disableButton} onClick={() => handleSettingsModalSubmit()} />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default Loader(ReconciledReports)
