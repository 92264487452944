import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import moment from 'moment'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { ButtonComponent } from 'Components/UIComponents'

const AddTotalCertifiedStock = ({ visible, setVisible, selectedValue, selectedRowData, reportName }) => {
  const { t } = useTranslation()

  const [selectedRobustaDate, setSelectedRobustaDate] = useState('')
  const [selectedArabicaDate, setSelectedArabicaDate] = useState('')
  const [robustaStockData, setRobustaStockData] = useState({
    fileName: reportName,
    date: '',
    certifiedStocks: '',
    ComparedToPreDay: '',
    nonTenderable: '',
    suspended: ''
  })

  const [arabicaStockData, setArabicaStockData] = useState({
    fileName: reportName,
    date: '',
    certifiedStocks: '',
    ComparedToPreDay: '',
    PendingGradingReport: '',
    grading: ''
  })

  useEffect(() => {
    if (selectedRowData && visible) {
      const getFormattedDate = (rowData) => {
        const dateValue = rowData.date || rowData.Date

        return dateValue ? moment(dateValue, 'DD-MMM-YYYY').toDate() : null
      }

      const formattedDate = getFormattedDate(selectedRowData)

      if (selectedValue?.idMarket === 1) {
        setRobustaStockData({
          ...robustaStockData,
          fileName: reportName,
          date: selectedRowData.date,
          certifiedStocks: selectedRowData.certifiedStocks,
          ComparedToPreDay: selectedRowData.ComparedToPreDay,
          nonTenderable: selectedRowData.nonTenderable,
          suspended: selectedRowData.suspended
        })
        setSelectedRobustaDate(formattedDate)
      } else {
        setArabicaStockData({
          ...arabicaStockData,
          fileName: reportName,
          date: formattedDate,
          certifiedStocks: selectedRowData.certifiedStocks,
          ComparedToPreDay: selectedRowData.ComparedToPreDay,
          PendingGradingReport: selectedRowData.PendingGradingReport,
          grading: selectedRowData.grading
        })
        setSelectedArabicaDate(formattedDate)
      }
    }
  }, [selectedRowData, visible, selectedValue, reportName])

  const handleChange = (fieldName, newValue) => {
    if (selectedValue?.idMarket === 1) {
      setRobustaStockData((previousState) => ({
        ...previousState,
        [fieldName]: newValue
      }))
    } else {
      setArabicaStockData((previousState) => ({
        ...previousState,
        [fieldName]: newValue
      }))
    }
  }

  const handleDateChange = (e) => {
    const selectedDate = e.value

    const formattedDate = selectedDate ? moment(selectedDate).format('DD-MMM-YYYY') : ''

    if (selectedValue?.idMarket === 1) {
      setSelectedRobustaDate(e.value)
      setRobustaStockData({ ...robustaStockData, date: formattedDate })
    } else {
      setSelectedArabicaDate(e.value)
      setArabicaStockData({ ...arabicaStockData, date: formattedDate })
    }
  }

  const resetData = () => {
    if (selectedValue?.idMarket === 1) {
      setRobustaStockData({
        date: '',
        certifiedStocks: '',
        ComparedToPreDay: '',
        nonTenderable: '',
        suspended: ''
      })
    } else {
      setArabicaStockData({
        date: '',
        certifiedStocks: '',
        ComparedToPreDay: '',
        PendingGradingReport: '',
        grading: ''
      })
    }
    setSelectedRobustaDate('')
    setSelectedArabicaDate('')
  }

  const closeDialogModal = () => {
    resetData()
    setVisible(false)
  }

  const handleSubmitData = () => {
    if (selectedValue?.idMarket === 1) {
      console.log(robustaStockData)
    } else {
      console.log(arabicaStockData)
    }
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const requiredFields = {
    date: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Date' }),
    certifiedStocks: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Certified Stocks' }),
    ComparedToPreDay: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Compare To Previous Day' }),
    nonTenderable: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Non Tenderable' }),
    suspended: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Suspended' }),
    PendingGradingReport: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Pending Grading Report' }),
    grading: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Daily Grading Summary' })
  }

  const validate = (data) => {
    const errors = {}

    Object.keys(requiredFields).forEach((field) => {
      if (data[field] === undefined || data[field] === '') {
        errors[field] = requiredFields[field]
      }
    })

    return errors
  }

  const { date: robustaDate, certifiedStocks: robustaCertifiedStocks, ComparedToPreDay: robustaComparedToPreDay, nonTenderable: robustaNonTenderable, suspended: robustaSuspended, marketType } = robustaStockData
  const { date: arabicaDate, certifiedStocks: arabicaCertifiedStocks, ComparedToPreDay: arabicaComparedToPreDay, PendingGradingReport: arabicaPendingGradingReport, grading: arabicaGrading, marketType: arabicMarket } = arabicaStockData

  const isButtonDisabled = !robustaDate || !robustaCertifiedStocks || !robustaComparedToPreDay || !robustaNonTenderable || !robustaSuspended || !marketType
  const submitButtonDisabled = !arabicaDate || !arabicaCertifiedStocks || !arabicaComparedToPreDay || !arabicaPendingGradingReport || !arabicaGrading || !arabicMarket

  const footerContent = (
    <div className="footer-button-container">
      <ButtonComponent type="cancel" onClick={closeDialogModal} />
      <ButtonComponent type="submit" onClick={handleSubmitData} disabled={selectedValue?.idMarket === 1 ? isButtonDisabled : submitButtonDisabled} />
    </div>
  )

  return (
    <Dialog header={`${t('TOTAL_CERTIFIED_STOCK')} - ${selectedValue?.marketName}`} closable={true} visible={visible} className="total-certified-stock-modal" onHide={closeDialogModal} footer={footerContent}>
      <div className="form-wrapper">
        {selectedValue?.idMarket === 1 && (
          <Form
            onSubmit={closeDialogModal}
            initialValues={robustaStockData}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid-input">
                  <Field
                    name="date"
                    render={({ input, meta }) => (
                      <div className="calendar-wrapper">
                        <span className="p-float-label">
                          <Calendar {...input} id="date" name="date" value={selectedRobustaDate} onChange={handleDateChange} maxDate={new Date()} showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="date" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('DATE')} <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="certifiedStocks"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="certified-stocks"
                            {...input}
                            value={robustaStockData.certifiedStocks}
                            onChange={(e) => {
                              handleChange('certifiedStocks', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="certified-stocks" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('CERTIFIED_STOCKS')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="ComparedToPreDay"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="compare-pre-day"
                            {...input}
                            value={robustaStockData.ComparedToPreDay}
                            onChange={(e) => {
                              handleChange('ComparedToPreDay', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="compare-pre-day" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('COMPARE_TO_PRE_DAY')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="nonTenderable"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="non-tenderable"
                            {...input}
                            value={robustaStockData.nonTenderable}
                            onChange={(e) => {
                              handleChange('nonTenderable', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="non-tenderable" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('NON_TENDERABLE')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="suspended"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="suspended"
                            {...input}
                            value={robustaStockData.suspended}
                            onChange={(e) => {
                              handleChange('suspended', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="suspended" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('SUSPENDED')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>
              </form>
            )}
          />
        )}

        {selectedValue?.idMarket === 2 && (
          <Form
            onSubmit={closeDialogModal}
            initialValues={arabicaStockData}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid-input">
                  <Field
                    name="date"
                    render={({ input, meta }) => (
                      <div className="calendar-wrapper">
                        <span className="p-float-label">
                          <Calendar {...input} id="date" name="date" value={selectedArabicaDate} onChange={handleDateChange} maxDate={new Date()} showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="date" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('DATE')} <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                  <Field
                    name="certifiedStocks"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="certified-stocks"
                            {...input}
                            value={arabicaStockData.certifiedStocks}
                            onChange={(e) => {
                              handleChange('certifiedStocks', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="certified-stocks" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('CERTIFIED_STOCKS')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="ComparedToPreDay"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="compare-pre-day"
                            {...input}
                            value={arabicaStockData.ComparedToPreDay}
                            onChange={(e) => {
                              handleChange('ComparedToPreDay', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="compare-pre-day" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('COMPARE_TO_PRE_DAY')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                  <Field
                    name="PendingGradingReport"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="pending-grading"
                            {...input}
                            value={arabicaStockData.PendingGradingReport}
                            onChange={(e) => {
                              handleChange('PendingGradingReport', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="pending-grading" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('PENDING_GRADING_REPORT')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="grading"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputTextarea
                            id="grading-summary"
                            {...input}
                            value={arabicaStockData.grading}
                            onChange={(e) => {
                              handleChange('grading', e.target.value)
                            }}
                            className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                          />
                          <label htmlFor="grading-summary" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                            {t('DAILY_GRADING_SUMMARY')}
                            <span className="required-text-field">*</span>
                          </label>
                        </span>
                        <div className="error-message"> {getFormErrorMessage(meta)}</div>
                      </div>
                    )}
                  />
                </div>
              </form>
            )}
          />
        )}
      </div>
    </Dialog>
  )
}

export default AddTotalCertifiedStock
